import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Switch,
} from "@material-tailwind/react";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import userImg from "../../assets/images/user.png";
import CancelPlanModal from "./CancelPlanModal";
import UpdatePlanModal from "./UpdatePlanModal";
import UpdateProfile from "./UpdateProfile";

const Account = () => {
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openCancelPlan, setOpenCancelPlan] = useState(false);
  const [openUpdatePlan, setOpenUpdatePlan] = useState(false);
  const user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;
  const userFields = user?.fields;
  const navigate = useNavigate();

  return (
    <>
      <Card className="bg-lightPrimary dark:bg-primary h-[calc(100vh-162px)] overflow-y-scroll scroll-hidden">
        <CardBody className="p-2 md:p-6">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex-1">
              {/* Profile Card */}
              <Card className="w-full bg-lightSecondary dark:bg-secondary shadow-lg">
                <CardHeader
                  floated={false}
                  className="rounded-full h-48 w-48 mx-auto ring-offset-4 ring-8 ring-lightPrimary dark:ring-primary mt-11 mb-[6px] shadow-[0px_0px_30px_7px_rgba(3,43,102,1)]  dark:shadow-[0px_0px_30px_7px_rgba(182,197,227,1)]"
                >
                  <img
                    src={userFields?.Image || userImg}
                    alt="user"
                    className="h-full object-cover w-full"
                  />
                </CardHeader>
                <CardBody className="relative p-4 pt-8 flex flex-col justify-between h-full">
                  <div>
                    <p className="font-bold  text-black dark:text-white text-2xl ">
                      {userFields?.Name}
                    </p>

                    <div className="mt-4 text-sm sm:text-md">
                      <div className="flex justify-between items-center">
                        <p className="text-gray-900 dark:text-gray-500">
                          Email:
                        </p>
                        <p className="dark:text-blue-500 font-bold break-all w-44 sm:w-full text-right leading-5 tracking-tighter md:tracking-wider">
                          {userFields?.Email}
                        </p>
                      </div>
                      <div className="flex justify-between items-center my-4">
                        <p className="text-gray-900 dark:text-gray-500">
                          Phone No.:
                        </p>
                        <p className="dark:text-blue-500 font-bold tracking-wider">
                          {userFields?.Country_code} {userFields?.Mobile}
                        </p>
                      </div>
                      <div className="flex justify-between items-center mb-4">
                        <p className="text-gray-900 dark:text-gray-500">
                          Country:
                        </p>
                        <p className="dark:text-blue-500 font-bold tracking-wider">
                          {userFields?.Country}
                        </p>
                      </div>
                    </div>
                  </div>

                  <Button
                    size="lg"
                    fullWidth
                    className="tracking-wider bg-primary mt-4"
                    onClick={() => setOpenUpdate(true)}
                  >
                    Update profile information
                  </Button>
                </CardBody>
              </Card>
            </div>

            {/* Right Side */}
            <div className="flex-1">
              <div className="flex flex-col gap-4">
                {/* Notification */}
                <div>
                  <Card className="dark:bg-secondary bg-lightSecondary h-full">
                    <CardBody className="p-4 dark:text-gray-400">
                      <p className="text-black dark:text-white font-bold text-xl">
                        Notifications
                      </p>
                      <p className="border-b border-white my-4"></p>
                      <div className="flex flex-col justify-center items-center h-full w-full">
                        {/* <div className="flex items-center  justify-between w-48 my-4">
                          <p className="text-black dark:text-white ">
                            SMS Notification
                          </p>
                          <Switch
                            color="blue"
                            checked={userFields?.Is_sms_notifications_enabled}
                          />
                        </div> */}
                        <div className="flex items-center  justify-between w-48">
                          <p className="text-black dark:text-white ">
                            Email Notification
                          </p>
                          <Switch
                            color="blue"
                            checked={userFields?.Is_email_notifications_enabled}
                          />
                        </div>
                        <div className="flex items-center gap-4 my-4">
                          <div className="flex items-center gap-2">
                            <p className="bg-blue-500 rounded-full w-2 p-2"></p>
                            <p>- ON</p>
                          </div>
                          <div className="flex items-center gap-2">
                            <p className="bg-[#cfd8dc] rounded-full w-2 p-2"></p>
                            <p>- OFF</p>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>

                {/* Actions */}
                <div>
                  <Card className="dark:bg-secondary bg-lightSecondary h-full">
                    <CardBody className="p-4">
                      <p className="dark:text-white font-bold text-xl text-black">
                        Actions
                      </p>
                      <p className="border-b border-white my-4"></p>
                      <Card className="dark:bg-primary bg-lightPrimary h-28 flex justify-center items-center w-full">
                        <CardBody className="dark:text-gray-400">
                          {userFields?.Role === "admin" ||
                          userFields?.Is_staff_member ? (
                            <p className="">
                              You are now viewing as{" "}
                              <span className="font-bold text-red-500">
                                Admin
                              </span>
                            </p>
                          ) : (
                            <p className="">
                              You are now on{" "}
                              <span className="font-bold text-red-500">
                                {userFields?.Plan_name === "Proplus"
                                  ? "Pro+"
                                  : userFields?.Plan_name || "Free Trial"}
                              </span>{" "}
                              Plan
                            </p>
                          )}
                        </CardBody>
                      </Card>
                      <div className="flex flex-col gap-4 mt-4">
                        <Button
                          className="bg-primary tracking-wider"
                          size="lg"
                          onClick={() => {
                            if (userFields?.Subscription_id) {
                              setOpenUpdatePlan(true);
                            } else {
                              navigate("/pricing");
                            }
                          }}
                        >
                          Update plan
                        </Button>

                        <Button
                          className="bg-red-500 tracking-wider"
                          size="lg"
                          onClick={() => setOpenCancelPlan(true)}
                          disabled={!userFields?.Subscription_id}
                        >
                          Cancel Subscription
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      <UpdateProfile
        open={openUpdate}
        handler={() => setOpenUpdate(!openUpdate)}
        user={user}
      />

      <UpdatePlanModal
        open={openUpdatePlan}
        handler={() => setOpenUpdatePlan(!openUpdatePlan)}
      />
      <CancelPlanModal
        open={openCancelPlan}
        handler={() => setOpenCancelPlan(!openCancelPlan)}
      />
    </>
  );
};

export default Account;
