import { IoIosTimer } from "react-icons/io";
import { IoAnalyticsOutline } from "react-icons/io5";
import { MdOutlineOnlinePrediction } from "react-icons/md";
import { PiCubeTransparentLight } from "react-icons/pi";
import { RiFeedbackLine, RiGuideLine, RiPassValidLine } from "react-icons/ri";

const menuOptions = [
  {
    id: 4,
    name: "User Guide",
    link: "/user-guide",
    icon: <RiGuideLine />,
  },
  {
    id: 1,
    name: "Match Prediction",
    link: "/",
    icon: <MdOutlineOnlinePrediction />,
  },
  {
    id: 2,
    name: "Handicap Prediction",
    link: "/handicap-prediction",
    icon: <IoAnalyticsOutline />,
  },
  // {
  //   id: 3,
  //   name: "Multi/Chain Prediction",
  //   link: "/multi-prediction",
  //   icon: <PiCubeTransparentLight />,
  // },
  {
    id: 5,
    name: "Feedback",
    link: "/feedback",
    icon: <RiFeedbackLine />,
  },
  {
    id: 6,
    name: "Our Accuracy",
    link: "/our-accuracy",
    icon: <RiPassValidLine />,
  },
  {
    id: 7,
    name: "ROI",
    link: "/roi",
    icon: <IoIosTimer />,
  },
];

export default menuOptions;
