import React from 'react'

const HandicapRoi = () => {
  return (
    <div>
         <h3 className='text-white font-semibold text-3xl text-center py-20'>Handicap ROI coming soon...</h3>
    </div>
  )
}

export default HandicapRoi