import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";
import { userTimeZone } from "../utils/timeZone";


export const useAllFinishedMatches = ({ game, date}) => {
  return useQuery({
    queryKey: ["accuracy", game, date],
    queryFn: async () => {
      const response = await fetcher.get(`/api/search/game/V2`,
        {
          params: {
            date,
            game,
            zone: userTimeZone,
            currentDate: new Date().toISOString(),
          },
        }
      );
      return response.data;
    },

    enabled: !!game,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
