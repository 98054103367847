import { Dialog, DialogBody, DialogHeader } from '@material-tailwind/react'
import React from 'react'
import { MdOutlineClose } from 'react-icons/md'

const CricketPrediction = ({open, handleOpen, data}) => {
  return (
    <Dialog
      open={open}
      handler={handleOpen}
      className=" p-6 bg-lightSecondary dark:bg-primary max-h-[90vh] overflow-auto scroll-hidden"
      size="md"
    >
      <DialogHeader className="text-black dark:text-white text-center">
        <div className="w-full flex justify-between items-center">
          <p> Our Prediction</p>
          <div>
            <button
              className=" bg-secondary/10 text-secondary dark:bg-lightPrimary/10 dark:text-lightPrimary p-2 w-12 h-12 rounded-full flex items-center justify-center"
              onClick={handleOpen}
            >
              <MdOutlineClose />
            </button>
          </div>
        </div>
      </DialogHeader>
      <DialogBody className="p-2 md:p-4">
        <div className="lg:px-6 px-2 py-6 shadow bg-lightSecondary dark:bg-lightPrimary/10 rounded-xl">
          {/* top part of the code */}
          <div className="flex justify-between items-center gap-4">
            <div className="flex flex-col justify-center items-center gap-2">
              <img
                className="lg:w-14 w-8 h-8 lg:h-14"
                src={data?.Team1_logo}
                alt={`${data?.Team1_name} logo`}
              />
              <p className="font-bold text-xs md:text-lg text-center text-secondary dark:text-lightPrimary">
                {data?.Team1_name}
              </p>
            </div>

            <div>
              <p className="text-lg md:text-4xl font-semibold text-secondary dark:text-lightPrimary">
                {data?.Time}
              </p>
              <p className="text-xs md:text-base text-secondary dark:text-lightPrimary">
                {data?.Date}
              </p>
            </div>

            <div className="flex flex-col justify-center items-center gap-2">
              <img
                className="lg:w-14 w-8 h-8 lg:h-14"
                src={data?.Team2_logo}
                alt={`${data?.Team2_name} logo`}
              />
              <p className="font-bold text-xs md:text-lg  text-center text-secondary dark:text-lightPrimary">
                {data?.Team2_name}
              </p>
            </div>
          </div>

          <div className="w-full h-[1px] dark:bg-secondary/50 my-8"></div>

          <div className="">
              <h3 className="text-xl md:2xl font-semibold text-green-700">
                Winner Odds
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mt-2">
                <div className="flex justify-between border border-secondary/10 bg-primary/5 dark:border-lightSecondary/5 dark:bg-secondary/20 rounded-sm p-2">
                  <p className="text-sm md:text-base text-secondary dark:text-lightPrimary font-semibold">
                    {data?.Team1_name}
                  </p>
                  <p className="text-xs md:text-sm font-semibold text-secondary dark:text-lightSecondary ">
                    {data?.BestOdds1 ? data?.BestOdds1 : ""}
                  </p>
                </div>
                <div className="flex justify-between border border-secondary/10 bg-primary/5 dark:border-lightSecondary/5 dark:bg-secondary/20 rounded-sm p-2">
                  <p className="text-sm md:text-base text-secondary dark:text-lightPrimary font-semibold">
                    {data?.Team2_name}
                  </p>
                  <p className="text-xs md:text-sm font-semibold text-secondary dark:text-lightSecondary ">
                    {data?.BestOdds2 ? data?.BestOdds2 : ""}
                  </p>
                </div>
              </div>

              <div className="mt-8">
                <h2 className="text-xl md:text-xl font-semibold text-center">
                  We Predict :{" "}
                  <span className="text-green-700">{data?.Prediction}</span>
                </h2>
              </div>
            </div>

          {data?.MostSixPrediction && (
              <div className="w-full h-[1px] dark:bg-secondary/50 my-8"></div>
            )}

            {/* most sixes odds and prediction */}
            {data?.MostSixPrediction && (
              <div className="">
                <h3 className="text-xl md:2xl font-semibold text-green-700">
                  Most Sixes Odds
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mt-2">
                  <div className="flex justify-between border border-secondary/10 bg-primary/5 dark:border-lightSecondary/5 dark:bg-secondary/20 rounded-sm p-2">
                    <p className="text-sm md:text-base text-secondary dark:text-lightPrimary font-semibold">
                      {data?.Team1_name}
                    </p>
                    <p className="text-xs md:text-sm font-semibold text-secondary dark:text-lightSecondary ">
                      {data?.HomeSixOdds ? data?.HomeSixOdds : ""}
                    </p>
                  </div>
                  <div className="flex justify-between border border-secondary/10 bg-primary/5 dark:border-lightSecondary/5 dark:bg-secondary/20 rounded-sm p-2">
                    <p className="text-sm md:text-base text-secondary dark:text-lightPrimary font-semibold">
                      {data?.Team2_name}
                    </p>
                    <p className="text-xs md:text-sm font-semibold text-secondary dark:text-lightSecondary ">
                      {data?.AwaySixOdds ? data?.AwaySixOdds : ""}
                    </p>
                  </div>
                </div>

                <div className="mt-8">
                  <h2 className="text-xl md:text-xl font-semibold text-center text-secondary dark:text-lightPrimary">
                    We Predict :{" "}
                    <span className="text-green-700">
                      {data?.MostSixPrediction}
                    </span>
                  </h2>
                </div>
              </div>
            )}
        </div>
      </DialogBody>
    </Dialog>
  )
}

export default CricketPrediction