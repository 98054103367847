import { useEffect, useId, useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const HandicapNeedleChart = ({ isLoading, accuracyData }) => {

    const id = useId();

    // hooks
    const [responsiveWidth, setResponsiveWidth] = useState(200);
    const [responsiveHeight, setResponsiveHeight] = useState(120);

    // handle resize
    const handleResize = () => {
        const width = Math.min(window.innerWidth * 0.4, 300);
        const height = width * 0.4;

        setResponsiveWidth(width);
        setResponsiveHeight(height);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
    }, []);


    const cx = responsiveWidth / 2.15;
    const cy = responsiveHeight;
    const iR = responsiveHeight / 1.5;
    const oR = Math.min(responsiveWidth, responsiveHeight) / 1;
    const value = accuracyData?.winParc;

    const soccerData = [
        {
            name: "Lost",
            value: isNaN(parseFloat(accuracyData?.winParc)) ? 100 : accuracyData?.lost,
            color: "#d1d1d1",
        },
        {
            name: "Win",
            value: accuracyData?.win,
            color: "#2559e8"
        },
    ];


    // for the pie chart
    const RADIAN = Math.PI / 180;
    const needle = (value, soccerData, cx, cy, iR, oR, color) => {
        let total = 105;
        if (soccerData) {
            total = 0;
            soccerData.forEach((v) => {
                total += v.value;
            });
        }


        const ang = 180.0 * (1 - value / total);
        const length = (iR + 2 * oR) / 3;
        const sin = Math.sin(-RADIAN * ang);
        const cos = Math.cos(-RADIAN * ang);
        const r = 5;
        const x0 = cx + 5;
        const y0 = cy + 5;
        const xba = x0 + r * sin;
        const yba = y0 - r * cos;
        const xbb = x0 - r * sin;
        const ybb = y0 + r * cos;
        const xp = x0 + length * cos;
        const yp = y0 + length * sin;

        return [
            <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" key={id} />,
            <path key={id}
                d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
                stroke="#none"
                fill={color} />,
        ];
    };



    return (
        <div>
            {
                isLoading ? <span className="loading loading-spinner text-gray loading-md h-[145px]"></span>
                    :
                    <PieChart width={responsiveWidth} height={responsiveHeight}>
                        <Pie
                            dataKey="value"
                            startAngle={0}
                            endAngle={180}
                            data={soccerData}
                            cx={cx}
                            cy={cy}
                            innerRadius={iR}
                            outerRadius={oR}
                            fill="#8884d8"
                            stroke="none"
                        >
                            {soccerData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                        </Pie>

                        {needle(value, null, cx, cy, iR, oR, "#2559e8")}
                    </PieChart>

            }

            {/* old version of percentage */}
            {/* {!isLoading && <p className='text-primary font-semibold mt-1 text-xl text-center'>{accuracyData?.winParc}%</p>} */}

            {/* new version of percentage */}
            {!isLoading && accuracyData?.winParc !== "NaN" ?
                <p className="text-primary font-semibold mt-1 text-xl text-center">
                    {accuracyData?.winParc}%
                </p>
                :
                <p className="text-primary font-semibold mt-1 text-xl text-center">
                    0.00
                </p>
            }

        </div>
    );
};

export default HandicapNeedleChart;