import React, { useState } from "react";
import AuthContainer from "../components/Auth/AuthContainer";
import SignUpForm from "../components/Auth/SignUpForm";


const SignUp = () => {


  return (
    <div>
      <AuthContainer>
        <SignUpForm />
      </AuthContainer>
    </div>
  );
};

export default SignUp;
