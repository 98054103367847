export default function CheckBox({ name, title, register, errors }) {
  return (
    <div>
      <div className="inline-flex items-center mt-3">
        <label className="flex items-center cursor-pointer relative">
          <input
            type="checkbox"
            name={name}
            className="peer h-4 w-4 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-[#131E4A] checked:bg-[#131E4A] checked:border-[#131E4A]"
            id={name}
            {...register(name, {
              required: {
                value: true,
                message: 'This field is required',
              },
            })}
          />
          <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-3.5 w-3.5"
              viewBox="0 0 20 20"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth={1}
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </label>
        <p className="ml-2">{title}</p>
      </div>
      <div className="label">
        {(errors?.type === 'required' || errors?.type === 'manual') && (
          <span className="text-red-500 text-xs">{errors.message}</span>
        )}
      </div>
    </div>
  );
}
