import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";


const useSingleBlog = ({ id }) => {

    const { isLoading: singleBlogLoading, data: singleBlog, refetch: singleBlogRefetch } = useQuery({
        queryKey: ["single-blog", id],
        enabled: !!id,
        queryFn: async () => {
            const res = await fetcher(`/api/blogs/${id}`);
            return res.data;
        }
    })

    return { singleBlogLoading, singleBlog, singleBlogRefetch };
};

export default useSingleBlog;