import { Button, IconButton } from "@material-tailwind/react";
import Cookies from "js-cookie";
import React from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { MdEmail, MdLock } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { fetcher } from "../../utils/authFetch";
import ExistingUserNotice from "./ExistingUserNotice";
import SocialLogin from "./SocialLogin";
import axios from "axios";
import { kodeendLogin } from "../../utils/kodeendLogin";

const LoginForm = () => {
  const [visible, setVisible] = React.useState(false);
  const [noticeModal, setNoticeModal] = React.useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // login for chatbot leads
  // const kodeendLogin = async () => {
  //   const data = {
  //     email: "admin@betterreturn.net",
  //     password: "Admin1234!",
  //   };

  //   try {
  //     const res = await axios.post(
  //       "https://api.kodeend.com/api/v1/auth/login",
  //       data
  //     );
  //     Cookies.set("kodeend_token", res?.data?.data?.token, { expires: 7 });
  //     navigate("/admin");
  //   } catch (error) {
  //     return Promise.reject(
  //       error?.response?.data?.message || "Something Wen't wrong"
  //     );
  //   }
  // };

  // Login api
  const loginAPI = async (data) => {
    try {
      const res = await fetcher.post("/api/auth/login", data);

      if (res?.data?.isExistingUser) {
        setNoticeModal(true);
        sessionStorage.setItem("email", res?.data?.data?.fields?.Email);
        return Promise.reject();
      }
      return res?.data;
    } catch (error) {
      return Promise.reject(
        error?.response?.data?.message || "Something Wen't wrong"
      );
    }
  };

  const onSubmit = async (input) => {
    toast.promise(
      loginAPI(input),
      {
        loading: "Logging in...",
        success: (data) => {
          Cookies.set("email", data?.data?.fields?.Email, { expires: 7 });
          Cookies.set("token", data?.data?.accessToken, { expires: 7 });
          Cookies.set("user", JSON.stringify(data?.data), { expires: 7 });
          if (data?.data?.fields?.Role === "admin") {
            kodeendLogin();
            navigate("/admin");
          } else {
            if (data?.data?.fields?.Email_verified_at) {
              navigate("/");
            } else {
              navigate(`/email-verify`);
            }
          }

          return data?.message || "Login successful";
        },
        error: (errorMessage) => errorMessage,
      },
      { id: "login" }
    );
  };

  return (
    <div className="">
      <h2 className="text-center text-3xl font-semibold mb-4">Login</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Email */}
        <div className="w-full relative">
          <MdEmail className="absolute left-3 top-4 text-xl" />
          <input
            type="email"
            placeholder="Your Email address"
            className="p-3 pl-10 bg-gray-100 border-l-4 border-primary focus:outline-none w-full rounded"
            {...register("email", {
              required: {
                value: true,
                message: "Please provide your email",
              },
              pattern: {
                value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                message: "Please enter a valid email",
              },
            })}
          />
          <label className="label">
            {(errors?.email?.type === "required" ||
              errors?.email?.type === "pattern") && (
              <span className="text-red-500 text-xs">
                {errors.email.message}
              </span>
            )}
          </label>
        </div>

        {/* Password */}
        <div className="form-control w-full relative mt-4">
          <MdLock className="absolute left-3 top-4 text-xl" />
          <input
            type={visible ? "text" : "password"}
            placeholder="Password"
            className="p-3 pl-10 bg-gray-100 border-l-4 border-primary focus:outline-none w-full rounded"
            {...register("password", {
              required: {
                value: true,
                message: "Please enter your password",
              },
            })}
          />
          <div className="absolute top-1 right-1">
            <IconButton
              variant="text"
              className="cursor-pointer text-xl rounded-full"
              onClick={() => setVisible(!visible)}
            >
              {visible ? <IoEye /> : <IoEyeOff />}
            </IconButton>
          </div>
          <label className="label">
            {errors.password?.type === "required" && (
              <span className="text-red-500 text-xs">
                {errors.password.message}
              </span>
            )}
          </label>
        </div>
        <div className="flex items-center justify-end mt-4">
          <Link
            to="/forgot-password"
            className="text-accent cursor-pointer text-xs hover:underline mb-2"
          >
            Forgot Password?
          </Link>
        </div>

        <Button className="w-full bg-primary" type="submit">
          Login
        </Button>
      </form>

      <p className="block text-center mt-2 text-sm">
        Don&apos;t have an account?{" "}
        <Link
          to="/signup"
          className="text-secondary link-hover hover:underline"
        >
          Register Now{" "}
        </Link>
      </p>

      {/* Divider */}
      <div className="flex items-center mt-4">
        <div className="border-t border-gray-900 w-full"></div>
        <p className="text-center px-4">OR</p>
        <div className="border-t border-gray-900 w-full"></div>
      </div>

      {/* Social Login */}
      <SocialLogin />

      <ExistingUserNotice
        open={noticeModal}
        handler={() => setNoticeModal(!noticeModal)}
      />
    </div>
  );
};

export default LoginForm;
