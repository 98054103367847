import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";
import { userTimeZone } from "../utils/timeZone";

export const useAllMatches = ({ date, filter, game }) => {
    return useQuery({
      queryKey: ["all-matches", date, filter, game],
      queryFn: async () => {
        const response = await fetcher.get("/api/v2/matches", {
          params: {
            date,
            zone: userTimeZone,
            filter,
            game
          },
        });
        return response.data;
      },
      enabled: !!date,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      cacheTime: 1000 * 60 * 5, // 5 minutes
    });
  };

//   /api/v2/matches?date=2024-07-11T04:19:09.199Z&zone=Asia/Dhaka&filter=all