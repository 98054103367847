import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";


const useAllCategories = () => {

    const { isLoading: categoriesLoading, data: allCategories, refetch: categoriesRefetch } = useQuery({
        queryKey: ["all-categories"],
        queryFn: async () => {
            const res = await fetcher.get("/api/blogs/category")
            return res.data;
        },
        // enabled: !!id,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        cacheTime: 1000 * 60 * 5, // 5 minutes
    })
    return { categoriesLoading, allCategories, categoriesRefetch }
}

export default useAllCategories;