import { Chip, Input } from "@material-tailwind/react";
import React, { useState } from "react";
import { usePaidUsers } from "../../../hooks/useUser";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import DataTable from "../../shared/DataTable";
import useDebounce from "../../../hooks/useDebounce";

const PaidUsers = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const { data, isLoading } = usePaidUsers({ search: debouncedSearch });

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const columns = [
    {
      field: "Id",
      headerName: "User ID",
    },
    {
      field: "Name",
      headerName: "Name",
    },
    {
      field: "Email",
      headerName: "Email",
    },
    {
      field: "Mobile",
      headerName: "Phone",
    },
    {
      field: "Country_code",
      headerName: "Country Code",
    },
    {
      field: "Plan_name",
      headerName: "Plan Name",
    },
    {
      field: "Stripe_id",
      headerName: "Stripe ID",
    },
    {
      field: "",
      headerName: "Plan Start Date",
      render: (row) => (
        <p className="mx-4">
          {row?.Plan_start_date
            ? new Date(row?.Plan_start_date).toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })
            : "N/A"}
        </p>
      ),
    },
    {
      field: "Plan_end_date",
      headerName: "Plan End Date",
      render: (row) => (
        <p className="mx-4">
          {row?.Plan_end_date
            ? new Date(row?.Plan_end_date).toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })
            : "N/A"}
        </p>
      ),
    },
    {
      field: "Plan_status",
      headerName: "Plan Status",
      render: (row) => {
        const currentDate = new Date();
        const startDate = row?.Plan_start_date
          ? new Date(row.Plan_start_date)
          : null;
        const endDate = row?.Plan_end_date ? new Date(row.Plan_end_date) : null;

        const isActive =
          startDate &&
          endDate &&
          currentDate >= startDate &&
          currentDate <= endDate;
        return (
          <p className={`mx-4 ${isActive ? "text-green-700" : "text-red-700"}`}>
            {isActive ? "Active" : "Inactive"}
          </p>
        );
      },
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between">
        <Chip
          variant="ghost"
          color="blue"
          size="lg"
          value={`Paid users: ${data?.meta?.total || 0}`}
          className="max-w-min tracking-widest"
          icon={
            <span className="mx-auto mt-2 block h-2 w-2 rounded-full bg-blue-900 content-['']" />
          }
        />

        <div className="w-full md:w-72 bg-gray-200 rounded-lg">
          <Input
            onChange={(e) => {
              handleSearch(e);
            }}
            placeholder="Search..."
            labelProps={{ className: "hidden" }}
            className="outline-none border-none"
            icon={<MagnifyingGlassIcon className="h-5 w-5" />}
          />
        </div>
      </div>

      <div className="mt-6">
        <DataTable
          isLoading={isLoading}
          rows={
            data?.data
              ?.slice((page - 1) * limit, limit * page)
              ?.map((item, index) => ({
                recordId: item?.id,
                Serial: index + 1 + (page - 1) * limit,
                ...item?.fields,
              })) || []
          }
          columns={columns}
          total={data?.data?.length}
          pagination={true}
          paginationOptions={{
            page,
            setPage,
            limit,
          }}
        />
      </div>
    </>
  );
};

export default PaidUsers;
