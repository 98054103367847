

const CreateTags = ({ tags, setTags, emptyFieldError, existingData }) => {
    return (
        <div className="w-full flex flex-col justify-start items-start gap-2 mt-5">
            <label htmlFor="tags" className="font-medium">Tags</label>
            <input
                onChange={(e) => setTags(e.target.value)}
                type="text"
                value={tags}
                name="tags"
                id="tags"
                placeholder="tag1, tag2, tag3"
                className="focus:outline-none px-3 py-1 border border-gray-300 focus:border-gray-500 rounded-md w-full" />
            {/* empty field caution */}
            {emptyFieldError && !tags && <p className="text-red-600 bg-[#ff868636] font-semibold px-2 py-0.5 rounded text-sm">Please enter tag</p>}
        </div>
    );
};

export default CreateTags;