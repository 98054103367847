import { Button, Navbar } from "@material-tailwind/react";
import Cookies from "js-cookie";
import React from "react";
import { MdClose, MdMenu } from "react-icons/md";
import { Link, ScrollRestoration, useNavigate } from "react-router-dom";
import logo2 from "../assets/images/logo/newLogo.png";

export default function AdminHeader({ open, setOpen }) {
  const navigate = useNavigate();

  const handleLogOut = () => {
    Cookies.remove("token");
    Cookies.remove("user");
    Cookies.remove("email");
    Cookies.remove("kodeend_token");
    navigate("/login");
  };

  return (
    <Navbar className="min-w-full rounded-none font bg-secondary border-none">
      <div className="flex items-center justify-between">
        <p
          className="text-white text-xl p-2 hover:bg-gray-100 hover:text-white rounded block lg:hidden"
          onClick={() => setOpen(!open)}
        >
          {open ? <MdClose /> : <MdMenu />}
        </p>

        <Link to="/">
          <img className={`w-28 lg:w-44`} src={logo2} alt="logo" />
        </Link>

        <div className="hidden md:flex items-center gap-4">
          <Link to="/user-guide">
            <Button variant="outlined" color="blue" size="sm">
              View as User
            </Button>
          </Link>

          <Button
            className="bg-red-500 capitalize px-3 py-2 lg:px-4 lg:py-3"
            onClick={handleLogOut}
          >
            log out
          </Button>
        </div>
      </div>
      <ScrollRestoration />
    </Navbar>
  );
}
