"use client"

import { useEffect, useState } from "react";

const CreateSlug = ({
    slug,
    setSlug,
    emptyFieldError,
    isDuplicateSlug,
    title,
    isUpdate,
    emptySlug,
    setEmptySlug
}) => {

    // edit slug state
    const [isEditSlug, setIsEditSlug] = useState(false);

    // convert title to slug only when creating a new blog post
    const convertedSlug = !isUpdate ?
        title?.split(" ")?.join("-")?.toLowerCase()
        :
        slug;

    // setting convertedSlug as Slug
    useEffect(() => {
        if (!isEditSlug) {
            setSlug(convertedSlug);
            setEmptySlug("")
        }
    }, [isEditSlug, convertedSlug, setSlug, title, isUpdate, setEmptySlug]);

    return (
        <div className="w-full flex flex-col justify-start items-start gap-2">
            {isEditSlug === false && (
                <div
                    onClick={() => setIsEditSlug(true)}
                    className="w-full flex justify-end items-center"
                >
                    <button className="text-[#0990ff] text-sm px-1 py-0.5 border border-[#0990ff] rounded font-semibold">
                        Edit slug
                    </button>
                </div>
            )}

            {isEditSlug ? (
                <>
                    <label htmlFor="slug" className="font-medium">
                        Slug
                    </label>
                    <input
                        type="text"
                        defaultValue={(slug && slug)}
                        onChange={(e) => setSlug(e.target.value.toLowerCase())}
                        name="slug"
                        id="slug"
                        placeholder="write-your-article-slug"
                        className="focus:outline-none px-3 py-1 border border-gray-300 focus:border-gray-500 rounded-md w-full"
                    />
                    {/* slug preview */}
                    <p className="text-sm text-gray-700 font-medium">
                        <span className="p-0.5 bg-gray-100 font-semibold">Preview:</span>{" "}
                        https://www.betterreturn.net/blogs/{slug}
                    </p>
                    {/* empty field caution */}
                    {emptyFieldError && !slug && (
                        <p className="text-red-600 bg-[#ff868636] font-semibold px-2 py-0.5 rounded text-sm">
                            Slug is required
                        </p>
                    )}
                    {/* duplicate slug */}
                    {isDuplicateSlug && (
                        <p className="text-red-600 bg-[#ff868636] font-semibold px-2 py-0.5 rounded text-sm">
                            {isDuplicateSlug}
                        </p>
                    )}
                </>
            ) : (
                <>
                    <p className="text-sm text-gray-600 font-medium">
                        <span className="font-bold text-black">Slug: </span>
                        https://www.betterreturn.net/blogs/{convertedSlug}
                    </p>
                </>
            )}
            {/* empty field caution */}
            {emptySlug &&
                <p className="text-red-700 border border-red-700 bg-red-100/50 font-semibold px-2 py-0.5 rounded text-sm">
                    {emptySlug}
                </p>
            }
        </div>
    );
};

export default CreateSlug;