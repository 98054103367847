import { useState } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';

const QuillEditor = ({ description, setDescription, emptyFieldError }) => {

    const [activeTab, setActiveTab] = useState("edit")

    return (
        <>
            {/* selection button */}
            <div className="flex justify-start items-center">

                {/* edit button */}
                <button
                    onClick={() => setActiveTab("edit")} className={`px-2 py-1 ${activeTab === "edit" ? "bg-white" : "bg-gray-300"} text-black font-medium rounded-l`}>
                    Edit
                </button>

                {/* preview button */}
                <button
                    disabled={description === null || description.length === 0 || description === "<p><br></p>"}
                    onClick={() => setActiveTab("preview")}
                    className={`px-2 py-1 ${activeTab === "preview" ? "bg-white" : "bg-gray-300"} text-black font-medium rounded-r disabled:cursor-not-allowed disabled:opacity-30`}>
                    Preview
                </button>
            </div>


            {/* quill editor */}
            {activeTab === "edit" &&
                <div className="w-full bg-white p-5 rounded-md mb-10">
                    <ReactQuill
                        theme="snow"
                        value={description}
                        style={{ height: '500px' }}
                        onChange={setDescription}
                        className="w-full rounded-xl h-60 bg-white"
                    />
                    {/* total words */}
                    <div className="w-full p-5">
                        <p className="w-full text-right mt-8 mb-20 p-2 text-gray-700">
                            <span className="font-semibold">{description ? `${description?.split(' ')?.length - 1}` : "0"}</span> Words
                        </p>

                        {/* empty field caution */}
                        <>
                        {emptyFieldError && !description && <p className="text-red-600 font-semibold text-lg -mt-28">Please enter blog description</p>}
                        </>
                    </div>
                </div>
            }


            {/* preview edit */}
            {activeTab === "preview" &&
                <>
                    <div className="description w-full h-[540px] p-5 mb-20 rounded bg-white overflow-y-auto">
                        {parse(description)}
                    </div>
                </>
            }


        </>
    );
};

export default QuillEditor;