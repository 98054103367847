const CreateAuthor = ({ author, setAuthor, emptyFieldError, existingData }) => {


    return (
        <div className="w-full flex flex-col justify-start items-start gap-2">
            <label htmlFor="author" className="font-medium">Author</label>
            <input
                type="text"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
                name="author"
                placeholder="Who wrote the article"
                id="author"
                className="focus:outline-none px-3 py-1 border border-gray-300 focus:border-gray-500 rounded-md w-full" />
            {/* empty field caution */}
            {emptyFieldError && !author && <p className="text-red-600 bg-[#ff868636] font-semibold px-2 py-0.5 rounded text-sm">Please enter author</p>}
        </div>
    );
};

export default CreateAuthor;