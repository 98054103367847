import React from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import ValorantPredictionV2 from "./ValorantPredictionV2";
import ValorantMatchDetails from "./ValorantMatchDetails";
import { isEligible } from "../../utils/isEligible";
import UpdatePlanModal from "../shared/UpdatePlanModal";

const ValorantCardV2 = ({ data }) => {
  const [vPredictionOpen, setVPredictionOpen] = React.useState(false);
  const [vMatchDetailsOpen, setVMatchDetailsOpen] = React.useState(false);
  const [vMatchDetailsData, setVMatchDetailsData] = React.useState();
  const [vPredictionData, setVPredictionData] = React.useState();
  const [notEligibleOpen, setNotEligibleOpen] = React.useState(false);

  const handleVPrediction = (data) => {

    const eligible = isEligible(data);

    if (eligible) {
      setVPredictionData(data?.fields);
      setVPredictionOpen(true);
    } else {
      setNotEligibleOpen(true);
    }
  };

  const handleVMatchDetails = (data) => {
    setVMatchDetailsData(data);
    setVMatchDetailsOpen(true);
  }

  return (
    <>
      <div className="w-full bg-lightSecondary dark:bg-lightPrimary/10 text-secondary dark:text-lightSecondary min-h-[20vh] rounded-lg px-4 py-2">
        {/* league name and  */}
        <div className="flex justify-between items-center bg-primary/10 dark:bg-secondary/30 py-3 px-2 rounded-md">
          <div className="flex gap-2 items-center">
            <img
              src={data?.fields?.Tournament_logo}
              alt="soccer"
              className="w-4 h-4"
            />
            <p className="text-xs font-semibold">{data?.fields?.Event}</p>
          </div>
          <p className="text-xs font-bold bg-lightPrimary text-secondary px-2 rounded">
            {data?.fields?.gameType}
          </p>
        </div>

        {/* team name and logo section*/}
        <div className="mt-8 flex justify-between">
          <div className="flex gap-2 items-center">
            <img src={data?.fields?.T1Logo} alt="soccer" className="w-4 h-4" />
            <p className="text-sm font-semibold">
              {data?.fields?.Team1?.length > 15
                ? data?.fields?.Team1.slice(0, 15) + "..."
                : data?.fields?.Team1}
            </p>
          </div>
          <p className="font-semibold text-orange-600">VS</p>
          <div className="flex gap-2 items-center">
            <img src={data?.fields?.T2Logo} alt="soccer" className="w-4 h-4" />
            <p className="text-sm font-semibold">
              {data?.fields?.Team2?.length > 15
                ? data?.fields?.Team2.slice(0, 15) + "..."
                : data?.fields?.Team2}
            </p>
          </div>
        </div>

        {/* status and time and date */}
        <div className="mt-4 flex justify-between">
          <div className="flex gap-2 items-center">
            {data?.fields?.status === "Upcoming" ? (
              <FaRegStar className="text-black dark:text-white" />
            ) : (
              <FaStar
                className={`${
                  data?.fields?.Results === "FALSE"
                    ? "text-red-600"
                    : "text-green-600"
                }`}
              />
            )}
            <p
              className={`text-sm font-semibold ${
                data?.fields.status === "Finished"
                  ? "text-orange-600"
                  : data.fields.status === "Upcoming"
                  ? "text-green-600"
                  : "text-red-600"
              }`}
            >
              {data?.fields?.status}
            </p>
          </div>
          <p className="text-sm">
            {data?.fields?.Date} | {data?.fields?.Time}
          </p>
        </div>

        {/* start in hours */}
        <div className="mt-8 flex justify-center items-center bg-primary/10 dark:bg-secondary/30 py-2 rounded-md">
          {data.fields.startHours > 0 || data.fields.startMinutes > 0 ? (
            <p className="text-center text-sm font-semibold">
              {data.fields.startHours} hrs {data.fields.startMinutes} mins to
              start
            </p>
          ) : data.fields.Result ? (
            <p className="text-center text-sm font-semibold">
              Match has already finished
            </p>
          ) : (
            <p className="text-center text-sm font-semibold">
              Match has already started
            </p>
          )}
        </div>

        {/* button section */}

        <div className="mt-6 flex justify-between gap-2">
          <button className="w-full border border-secondary/50 hover:bg-secondary hover:text-lightSecondary dark:border-lightPrimary/20 dark:hover:bg-lightPrimary dark:hover:text-secondary text-xs font-semibold uppercase py-1.5 rounded-lg"
          onClick={() => handleVPrediction(data)}
          >
            Get prediction
          </button>

          <button className="w-full border border-secondary/50 hover:bg-secondary hover:text-lightSecondary dark:border-lightPrimary/20 dark:hover:bg-lightPrimary dark:hover:text-secondary text-xs font-semibold uppercase py-1.5 rounded-lg"
          onClick={() => handleVMatchDetails(data?.fields)}
          >
            MatCh Details
          </button>
        </div>
      </div>

      <ValorantPredictionV2
        open={vPredictionOpen}
        data={vPredictionData}
        handleOpen={() => setVPredictionOpen(!vPredictionOpen)}
      />

      <ValorantMatchDetails
        open={vMatchDetailsOpen}
        data={vMatchDetailsData}
        handleOpen={() => setVMatchDetailsOpen(!vMatchDetailsOpen)}
      />

      <UpdatePlanModal
        open={notEligibleOpen}
        handleOpen={() => setNotEligibleOpen(!notEligibleOpen)}
      />
    </>
  );
};

export default ValorantCardV2;
