import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  IconButton,
} from "@material-tailwind/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IoIosWarning } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import userImg from "../../assets/images/user.png";
import countries from "../../static/countries.json";
import { fetcher } from "../../utils/authFetch";
import { useDarkMode } from "../shared/moon/DarkModeContext";

const UpdateProfile = ({ open, handler, user }) => {
  const queryClient = useQueryClient();
  const userFields = user?.fields;
  const { darkMode } = useDarkMode();

  const [file, setFile] = useState(null);
  const [error, setError] = useState({
    name: "",
    message: "",
  });
  const [info, setInfo] = useState({
    image: userFields?.Image,
    name: userFields?.Name,
    phone: userFields?.Mobile,
    country: userFields?.Country,
  });

  useEffect(() => {
    setInfo({
      image: userFields?.Image,
      name: userFields?.Name,
      phone: userFields?.Mobile,
      country: userFields?.Country,
    });
  }, [userFields]);

  const { mutate: updateProfileApi, isPending } = useMutation({
    mutationFn: async (formData) =>
      fetcher.put(`/api/user/update?id=${user?.id}`, formData),
    onSuccess: (res) => {
      Cookies.set("user", JSON.stringify(res?.data?.data));
      toast.success("Profile updated successfully");
      queryClient.invalidateQueries(["user"]);
      handler();
      setInfo({});
      setFile(null);
    },
    onError: (error) => {
      setError({
        name: "response",
        message: error?.response?.data?.message || "Something went wrong",
      });
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    const selectedCountry = countries.find(
      (item) => item.name === info.country
    );

    if (!selectedCountry) {
      setError({
        name: "phone",
        message: "Please select a valid country",
      });
      return;
    }

    const validPhoneLengths = Array.isArray(selectedCountry?.phone_length)
      ? selectedCountry.phone_length
      : [selectedCountry.phone_length];

    if (!validPhoneLengths.includes(info?.phone?.length)) {
      setError({
        name: "phone",
        message: "Please enter a valid phone number",
      });
      return;
    }
    const fd = new FormData();
    fd.append("Name", info.name);
    fd.append("Mobile", info.phone);
    fd.append("Country", info.country);
    if (file) fd.append("Image", file);

    await updateProfileApi(fd);
  };
  return (
    <Dialog
      open={open}
      handler={handler}
      className="bg-lightPrimary dark:bg-primary max-h-[90vh] overflow-auto"
      size="sm"
    >
      <DialogHeader className="text-secondary dark:text-white text-center justify-between">
        <h2>Update Profile</h2>
        <IconButton
          color={darkMode ? "white" : "gray"}
          variant="text"
          onClick={handler}
          className="rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </DialogHeader>
      <DialogBody>
        <form className="flex flex-col gap-6" onSubmit={onSubmit}>
          <div className="flex items-center justify-center">
            <label
              htmlFor="image"
              className="relative border-2 border-blue-900 rounded-full"
            >
              <img
                src={info?.image || userImg}
                alt="Profile"
                className="w-32 h-32 rounded-full object-cover"
              />
              <input
                type="file"
                id="image"
                className="hidden"
                accept="image/*"
                onChange={(event) => {
                  const file = event?.target?.files[0];
                  if (file) {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                      setInfo({ ...info, image: event.target.result });
                      setFile(file);
                    };
                    reader.readAsDataURL(file);
                  }
                }}
              />
              <div className="absolute bottom-2 right-2 bg-blue-900 text-white rounded-full p-2 cursor-pointer shadow-sm">
                <MdEdit className="text-xl" />
              </div>
            </label>
          </div>

          <div className="flex flex-col items-start justify-start gap-1">
            <p className="text-gray-900 dark:text-gray-400 text-md">Name:</p>
            <input
              type="text"
              placeholder="Name"
              className="block w-full dark:bg-secondary bg-lightSecondary border-0 outline-none p-3 rounded-lg dark:text-white text-black"
              value={info.name}
              onChange={(e) => setInfo({ ...info, name: e.target.value })}
            />
          </div>

          <div className="flex flex-col items-start justify-start gap-1">
            <p className="text-gray-900 dark:text-gray-400 text-md">Country:</p>
            <select
              placeholder="Phone No"
              className="block w-full dark:bg-secondary bg-lightSecondary border-0 outline-none p-4 rounded-lg dark:text-white text-black"
              value={info.country}
              onChange={(e) => setInfo({ ...info, country: e.target.value })}
            >
              {countries.map((country, i) => (
                <option value={country?.name} key={i}>
                  {country?.name} ({country?.phone_code})
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col items-start justify-start gap-1">
            <p className="text-gray-900 dark:text-gray-400 text-md">
              Phone No:
            </p>
            <input
              type="tel"
              placeholder="Phone No"
              className="block w-full dark:bg-secondary bg-lightSecondary border-0 outline-none p-3 rounded-lg dark:text-white text-black"
              value={info.phone}
              onChange={(e) => setInfo({ ...info, phone: e.target.value })}
            />
          </div>

          {/* Alert */}
          {error?.message ? (
            <div className="flex items-center justify-start gap-2 w-full rounded-lg bg-red-500 bg-opacity-10 text-red-500 p-2">
              <IoIosWarning className="text-xl" />
              <p>{error?.message || "Something wen't wrong"}</p>
            </div>
          ) : null}

          <Button
            type="submit"
            fullWidth
            className="bg-blue-900 tracking-wider outline-none"
            ripple
            disabled={isPending}
            size="lg"
          >
            {isPending ? "Updating..." : "Update"}
          </Button>
        </form>
      </DialogBody>
    </Dialog>
  );
};

export default UpdateProfile;
