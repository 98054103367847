import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Tab,
  Tabs,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import Cookies from "js-cookie";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import tick from "../assets/icons/check.png";
import logo from "../assets/images/logo/newLogo.png";
import logo2 from "../assets/images/logo/logo_updated_2.png";
import { useDarkMode } from "../components/shared/moon/DarkModeContext";
import { usePlans } from "../hooks/usePlans";
import Loading from "../components/shared/Loading/Loading";
import { fetcher } from "../utils/authFetch";
// import Popup from "../components/shared/Popup";

const Pricing = () => {
  const user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;
  const navigate = useNavigate();
  const { darkMode } = useDarkMode();
  const [selected, setSelected] = useState("monthly");
  const { data: plans, isLoading } = usePlans({ filter: selected });

  // Create Payment Link
  const createPaymentLink = async ({ planId, email, includeTrial }) => {
    try {
      const { data } = await fetcher.post("/api/payment/create-payment-link", {
        planId,
        email,
        includeTrial,
      });
      return data?.data?.url;
    } catch (error) {
      console.log("Error Creating Payment Link");
    }
  };

  const handleClick = async (data, index) => {
    if (!user) {
      navigate("/login", { state: { from: "/pricing" } });
      return;
    }
    let paymentUrl = "/";

    // --- Use Pre created Payment link
    // const selectedPlan = plans?.find(
    //   (item, i) => item?.fields?.planId === data?.planId
    // );

    // if (user?.fields?.FreeTier) {
    //   paymentUrl = selectedPlan?.fields?.paymentLink;
    // } else {
    //   paymentUrl = selectedPlan?.fields?.trialPaymentLink;
    // }

    // --- Create payment link from backend
    paymentUrl = await createPaymentLink({
      planId: data?.planId,
      email: user?.fields?.Email,
      includeTrial: user?.fields?.FreeTier ? false : true,
    });
    window.location.href = paymentUrl;

    // navigate(`/subscription?plan=${data?.planId}`);
  };

  const TABS = [
    {
      label: "Monthly",
      value: "monthly",
      dis: "",
    },
    {
      label: "Semi Annual",
      value: "semiAnnual",
      dis: "10% discount",
    },
  ];
  const handleTabClick = (value) => {
    setSelected(value);
  };

  // const [showPopup, setShowPopup] = useState(true);

  // const closePopup = () => {
  //   setShowPopup(false);
  // };

  return (
    <div className="bg-primary">
      {/* {showPopup && <Popup closePopup={closePopup} />} */}
      <div className="max-w-screen-sm mx-auto pb-16 min-h-screen ">
        <div className=" mx-auto pt-8">
          <Link to="/" className="mx-auto">
            <img
              onClick={() => navigate("/")}
              className={`w-36 mx-auto`}
              src={darkMode ? logo : logo2}
              alt="logo"
            />
          </Link>
        </div>

        {/* <div className="max-w-screen-xl mx-auto px-4 mt-14 mb-4 bg-[#b6d3f0] py-2 rounded-lg">
          <p className="text-secondary text-lg text-center font-semibold">
            When making a payment via Stripe, please ensure that you use the
            same email address that you used during account registration
          </p>
        </div> */}

        <Tabs value={selected} className="py-8 max-w-screen-sm mx-auto w-96">
          <TabsHeader className="h-12">
            {TABS.map(({ label, value, dis }) => (
              <Tab
                onClick={() => handleTabClick(value)}
                key={value}
                value={value}
                className="flex flex-col leading-4 h-full"
              >
                <p>&nbsp;&nbsp;{label}&nbsp;&nbsp;</p>
                <span className="text-xs text-green-800 font-bold">{dis}</span>
              </Tab>
            ))}
          </TabsHeader>
        </Tabs>

        {/* <div className="max-w-screen-xl mx-auto px-4 mb-8 py-2 rounded-lg">
          <p className="text-red-600 text-lg text-center font-semibold">
            When making a payment via Stripe, please ensure that you use the
            same email address that you used during account registration
          </p>
        </div> */}

        <div className="max-w-screen-xl mx-auto px-4">
          {isLoading ? (
            <Loading />
          ) : (
            <div>
              <div
                className={`max-w-screen-sm mx-auto px-0 md:px-20`}
              >
                {plans?.map((item, index) => {
                  return (
                    <div key={index + 1}>
                      <Card
                        variant="gradient"
                        className="w-full lg:max-w-[22rem] rounded-3xl  lg:hover:scale-[1.02] transition-all delay-0 duration-300 group hover:bg-[#121c2e] hover:shadow-xl hover:shadow-[#121c2e] hover:-translate-y-4 h-full"
                      >
                        {item?.fields?.status && (
                          <p className="absolute z-10 bg-primary text-white py-1.5 px-4 text-xs rounded-xl -top-2  shadow-md shadow-primary ">
                            {item?.fields?.status}
                          </p>
                        )}

                        <CardHeader
                          floated={false}
                          shadow={false}
                          color="transparent"
                          className="m-0  rounded-none   text-center "
                        >
                          <Typography className="font-bold tracking-widest  normal-case text-[#0d47a1] bg-[#b6d3f0] py-4 rounded-t-3xl group-hover:bg-[#0d47a1] group-hover:text-[#b6d3f0]">
                            {item?.fields?.name}
                          </Typography>

                          <Typography className="mt-6  flex justify-center gap-1 text-7xl font-normal text-[#424446] group-hover:text-gray-200 delay-0 group-hover:animate-pulse mb-4">
                            <span className="mt-2 text-4xl">€</span>
                            {item?.fields?.type === "semiAnnual"
                              ? item?.fields?.price / 6
                              : item?.fields?.price}

                            <span className="self-end text-4xl">/mo</span>
                          </Typography>
                          {item?.fields?.discount && (
                            <div className="h-16">
                              <Typography className="text-blue-900 font-bold">
                                {item?.fields?.discount}
                              </Typography>
                              <Typography className="text-sm tracking-widest">
                                {item?.fields?.bill}
                              </Typography>
                            </div>
                          )}

                          <Typography className=" text-green-600 text-lg font-semibold tracking-widest animate-bounce mx-auto mt-0 mb-4">
                            {item?.fields?.offer} days free
                          </Typography>
                        </CardHeader>
                        <CardBody className="px-4 py-0">
                          <Button
                            onClick={() => {
                              handleClick(item?.fields, index);
                            }}
                            className="bg-primary tracking-widest mb-6 text-md hover:bg-secondary"
                            fullWidth
                          >
                            Choose Plan
                          </Button>

                          <ul className="flex flex-col gap-4 pb-6">
                            {item?.fields?.features?.map((d, i) => {
                              return (
                                <div key={i}>
                                  <li className="flex items-center gap-4 h-7">
                                    <img className="w-5 " src={tick} alt="" />
                                    <Typography className=" text-sm text-[#555a66] font-semibold group-hover:text-white delay-0">
                                      {d}
                                    </Typography>
                                  </li>
                                </div>
                              );
                            })}
                          </ul>
                        </CardBody>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
