import { Spinner } from "@material-tailwind/react";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { fetcher } from "../../utils/authFetch";
import { kodeendLogin } from "../../utils/kodeendLogin";

const GoogleCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const accessToken = params.get("token");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await fetcher.get("/api/user/get", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const userFields = res?.data?.data?.fields;
        Cookies.set("email", userFields?.Email, { expires: 7 });
        Cookies.set("user", JSON.stringify(res?.data?.data), { expires: 7 });
        Cookies.set("token", accessToken, { expires: 7 });

        if (userFields?.Role === "admin") {
          // window.location.href = `https://better-dash.kodeend.com?token=${accessToken}`;
          kodeendLogin();
          navigate("/admin");
        } else {
          navigate(`/user-guide`);
          // if (userFields?.Email_verified_at) {
          //   // window.location.href = `https://sports.kodeend.com?token=${accessToken}`;
          //   navigate(`/`);
          // } else {
          //   navigate(`/email-verify`);
          // }
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "Something Wen't wrong");
      } finally {
        setIsLoading(false);
      }
    };
    if (accessToken) {
      Cookies.set("token", accessToken, { expires: 7 });
      fetchUserData();
    }
  }, [accessToken, navigate]);

  return (
    <div className="grid place-items-center min-h-screen">
      {isLoading ? <Spinner color="blue" fontSize={24} /> : null}
    </div>
  );
};

export default GoogleCallback;
