import {
  Dialog,
  DialogBody,
  DialogHeader,
  IconButton,
} from "@material-tailwind/react";
import React from "react";
import { useSingleChatbot } from "../../../hooks/admin/useChatbot";
import { FaUserCircle } from "react-icons/fa";
import { AiOutlineRobot } from "react-icons/ai";
import Loading from "../../shared/Loading/Loading";

const MessageDialog = ({ open, handler, title, id }) => {
  const { data, isLoading } = useSingleChatbot(id);
  return (
    <Dialog
      open={open}
      handler={handler}
      className="bg-white  max-h-[90vh] overflow-auto"
      size="sm"
    >
      <DialogHeader className="text-black text-center justify-between">
        <h2>{title}</h2>
        <IconButton
          color="gray"
          variant="text"
          onClick={handler}
          className="rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </DialogHeader>
      <DialogBody className="text-black py-6">
        {/* Conversations */}
        <div className="mt-4 custom_scrollbar w-full h-[400px] py-2 overflow-y-auto">
          {data?.data?.messages &&
            data?.data?.messages?.map((chat) => {
              return (
                <div key={chat?._id}>
                  <div className="px-4 mt-5">
                    <div className="flex gap-2">
                      <div className="chat-image avatar">
                        <FaUserCircle className="text-4xl text-black" />
                      </div>
                      <div className="bg-[#00a7bd] text-white py-1 px-2 rounded-md">
                        {chat?.user}
                      </div>
                    </div>
                  </div>
                  <div className="px-4 mt-5">
                    <div className="flex gap-2 justify-end">
                      <div className="bg-[#0ca353] text-white px-2 py-1 rounded-md">
                        {chat?.bot}
                      </div>
                      <div className="chat-image avatar">
                        <AiOutlineRobot className="text-4xl text-black" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {isLoading ? <Loading className="h-40"/> : null}
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default MessageDialog;
