import { Button, Card, List, ListItem } from "@material-tailwind/react";

import { MdDashboard, MdGroups, MdSportsSoccer } from "react-icons/md";
import { GiLaserGun, GiMachineGunMagazine } from "react-icons/gi";
import { FaPenNib } from "react-icons/fa";
import { MdManageSearch } from "react-icons/md";
import { IoNewspaperOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const adminNavList = [
  {
    name: "Dashboard",
    link: "/admin",
    icon: <MdDashboard />,
  },
  {
    name: "Soccer",
    link: "/admin/matches/soccer",
    icon: <MdSportsSoccer />,
  },
  {
    name: "CS-GO",
    link: "/admin/matches/cs-go",
    icon: <GiMachineGunMagazine />,
  },

  {
    name: "Valorant",
    link: "/admin/matches/valorant",
    icon: <GiLaserGun />,
  },
  {
    name: "All Users",
    link: "/admin/users",
    icon: <MdGroups />,
  },
  {
    name: "Paid Users",
    link: "/admin/paid-users",
    icon: <MdGroups />,
  },
  {
    name: "Manage Blogs",
    link: "/admin/manageBlogs",
    icon: <MdManageSearch />,
  },
  {
    name: "Add Blog",
    link: "/admin/addBlog",
    icon: <FaPenNib />,
  },
  {
    name: "Newsletter list",
    link: "/admin/newsletterLists",
    icon: <IoNewspaperOutline />,
  },
  {
    name: "Lead",
    link: "/admin/lead",
    icon: <IoNewspaperOutline />,
  },
];

export default function AdminSideBar({ open, setOpen }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isCurrentPath = (path) => pathname === path;

  const handleLogOut = () => {
    Cookies.remove("token");
    Cookies.remove("user");
    Cookies.remove("email");
    navigate("/login");
  };

  return (
    <>
      {
        <Card
          className={`h-[calc(100vh-4.5rem)] ${
            open ? "fixed" : "hidden lg:block"
          } lg:static w-full max-w-[15rem] py-4 shadow-md shadow-[#344868] rounded-none border-none overflow-y-scroll scroll-hidden bg-[#d3e3fd] z-[50]`}
        >
          <div className="p-0">
            {adminNavList.map((item, index) => {
              return (
                <Link to={item.link} key={index} onClick={() => setOpen(!open)}>
                  <List>
                    <ListItem
                      className={`group cursor-pointer font hover:bg-white ${
                        isCurrentPath(item?.link)
                          ? "focus:bg-[#b7d3ff] bg-[#b7d3ff] rounded-full text-black font-bold"
                          : ""
                      }`}
                    >
                      <div className="flex items-center gap-4 ">
                        <p className="text-xl">{item.icon}</p>
                        <p
                          className={`text-sm group-hover:text-lg group-focus:text-black group-focus:font-extrabold transition-all`}
                        >
                          {item.name}
                        </p>
                      </div>
                    </ListItem>
                  </List>
                </Link>
              );
            })}

            <div className="mx-2 flex items-center gap-4 md:hidden">
              <Link to="/user-guide">
                <Button variant="outlined" color="blue" size="sm">
                  View as User
                </Button>
              </Link>

              <Button
                className="bg-red-500 capitalize px-3 py-2 lg:px-4 lg:py-3"
                onClick={handleLogOut}
              >
                log out
              </Button>
            </div>
          </div>
        </Card>
      }
    </>
  );
}
