import React, { useState } from "react";
import { useChatbot } from "../../../hooks/admin/useChatbot";
import DataTable from "../../shared/DataTable";
import { Button } from "@material-tailwind/react";
import MessageDialog from "./MessageDialog";

function ChatbotLead() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [messageDialog, setMessageDialog] = useState(false);
  const [conversationId, setConversationId] = useState(null);

  const { data, isLoading } = useChatbot({ page, limit });

  // console.log(data?.data);

  const handleViewMessages = (id) => {
    setConversationId(id);
    setMessageDialog(true);
  };

  const columns = [
    {
      field: "Serial",
      headerName: "Serial",
    },
    {
      field: "email",
      headerName: "Email",
      render: (row) => row?.emails[0] || "N/A",
    },
    {
      field: "phone",
      headerName: "Phone",
      render: (row) => row?.phones[0] || "N/A",
    },
    {
      field: "totalMessages",
      headerName: "Messages",
    },
    {
      field: "updatedAt",
      headerName: "Last Message Time",
      // 2024-06-24T10:14:11.464Z
      render: (row) => new Date(row?.updatedAt).toLocaleString(),
    },
    {
      field: "actions",
      headerName: "Actions",
      render: (row) => (
        <div className="flex space-x-2">
          <Button
            onClick={() => handleViewMessages(row?.recordId)}
            variant="outlined"
            color="blue"
            size="sm"
          >
            View Messages
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <h2 className="my-8 text-xl font-bold ">
        Total Conversations : {data?.meta?.total}
      </h2>

      <DataTable
        isLoading={isLoading}
        rows={
          data?.data
            ?.slice((page - 1) * limit, limit * page)
            ?.map((item, index) => ({
              recordId: item?._id,
              Serial: index + 1 + (page - 1) * limit,
              ...item,
            })) || []
        }
        columns={columns}
        total={data?.meta?.total || 0}
        pagination={true}
        paginationOptions={{
          page,
          setPage,
          limit,
        }}
      />

      <MessageDialog
        open={messageDialog}
        handler={() => setMessageDialog(!messageDialog)}
        title="All Conversations"
        id={conversationId}
      />
    </div>
  );
}

export default ChatbotLead;
