import { useEffect, useState } from "react";
import { IoImageOutline } from "react-icons/io5";




const CreateFeaturedImage = ({ data, setImage, image, emptyFieldError, existingData }) => {

    // hooks
    const [imageSrc, setImageSrc] = useState();

    // setting image source if it is updating
    useEffect(() => {
        image && setImageSrc(image)
    }, [image])

    // handle change
    function handleOnChange(event) {
        const reader = new FileReader();
        reader.onload = function (onLoadEvent) {
            setImageSrc(onLoadEvent.target.result);
        }
        setImage(event.target.files[0])
        reader.readAsDataURL(event.target.files[0]);
    }



    return (
        <div className=" relative">

            <form
                onChange={handleOnChange}
                className="my-5">
                <label htmlFor="featuredImage" className="font-medium">Featured image</label>
                <input type="file" id="featuredImage" accept="image/*" className="absolute -bottom-4 opacity-60 left-0" />
            </form>

            {
                imageSrc ?
                    <img
                        src={imageSrc}
                        alt={`featured`}
                        className="w-full mb-6"
                    />
                    :
                    data?.Image ?
                        <img
                            src={data?.Image}
                            alt={`${data?.Name}`}
                            className="w-full mb-6"
                        />
                        :
                        <div className="w-full overflow-x-hidden">
                            <IoImageOutline className="text-[250px] md:text-[200px] lg:text-[200px] text-gray-500 mb-6" />
                        </div>
            }

            {/* empty field caution */}
            {emptyFieldError && !image && <p className="text-red-600 bg-[#ff868636] font-semibold px-2 py-0.5 rounded mb-7 text-sm">Please enter featured image</p>}

        </div>
    );
};

export default CreateFeaturedImage;