import {
  Dialog,
  DialogBody,
  DialogHeader,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";

const TABLE_HEAD = ["", "Prediction", "Predicted Odds", "Status"];

const HandicapModalV2 = ({ open, handleOpen, data }) => {
  const [possibleOutcome, setPossibleOutcome] = useState(0.0);
  const [wallet, setWallet] = useState();
  const [investmentPercent, setInvestmentPercent] = useState(15);
  const [calcInvestment, setcalCInvestment] = useState();
  const [investment, setInvestment] = useState();

  const fixtureFields = data;

  const invetmentData = [
    fixtureFields?.T1CornerOdds
      ? {
          name: `${fixtureFields?.HomeTeam} (Corner Kicks)`,
          pOdds: fixtureFields.T1CornerOdds,
        }
      : null,
    fixtureFields?.T1GoalUOOdds
      ? {
          name: `${fixtureFields?.HomeTeam} (Goals)`,
          pOdds: fixtureFields.T1GoalUOOdds,
        }
      : null,
    fixtureFields?.T1YellowOdds
      ? {
          name: `${fixtureFields?.HomeTeam} (Yellow Cards)`,
          pOdds: fixtureFields.T1YellowOdds,
        }
      : null,
    fixtureFields?.T2CornerOdds
      ? {
          name: `${fixtureFields?.AwayTeam} (Corner Kicks)`,
          pOdds: fixtureFields.T2CornerOdds,
        }
      : null,
    fixtureFields?.T2GoalUOOdds
      ? {
          name: `${fixtureFields?.AwayTeam} (Goals)`,
          pOdds: fixtureFields.T2GoalUOOdds,
        }
      : null,
    fixtureFields?.T2YellowOdds
      ? {
          name: `${fixtureFields?.AwayTeam} (Yellow Cards)`,
          pOdds: fixtureFields.T2YellowOdds,
        }
      : null,
    fixtureFields?.TCornerOdds
      ? { name: `Total Corner Kicks`, pOdds: fixtureFields.TCornerOdds }
      : null,
    fixtureFields?.TGoalUOOdds
      ? { name: `Total Goals`, pOdds: fixtureFields.TGoalUOOdds }
      : null,
    fixtureFields?.TYellowOdds
      ? { name: `Total Yellow Cards`, pOdds: fixtureFields.TYellowOdds }
      : null,
  ];
  const filteredInvestmentData = invetmentData?.filter((item) => item !== null);

  const defaultSelectedName = `${fixtureFields?.HomeTeam} (Corner Kicks)`;
  const defaultSelectedPercentage = fixtureFields?.T1CornerOdds || null;

  const [selectedValue, setSelectedValue] = useState(defaultSelectedName);
  const [selectedPercentage, setSelectedPercentage] = useState(
    defaultSelectedPercentage
  );

  const handleDropdownChange = (event) => {
    const selectedName = event.target.value;
    const selectedData = filteredInvestmentData?.find(
      (item) => item?.name === selectedName
    );

    if (selectedData) {
      setSelectedValue(selectedName);
      setSelectedPercentage(selectedData?.pOdds);
      // Perform actions with the selected percentage value here
    } else {
      setSelectedValue("");
      setSelectedPercentage(null);
    }
  };

  useEffect(() => {
    // Set default values when the component mounts
    setSelectedValue(defaultSelectedName);
    setSelectedPercentage(defaultSelectedPercentage);
  }, [defaultSelectedName, defaultSelectedPercentage]);

  useEffect(() => {
    setcalCInvestment((wallet * investmentPercent) / 100);
    if (investment) {
      setPossibleOutcome(investment * selectedPercentage);
    } else {
      setPossibleOutcome(calcInvestment * selectedPercentage);
    }
  }, [
    wallet,
    investmentPercent,
    investment,
    selectedPercentage,
    calcInvestment,
  ]);

  const TABLE_ROWS = fixtureFields
    ? [
        {
          name: "Corner Kicks",
          prediction: fixtureFields.T1CornerPredict1,
          odds: fixtureFields.T1CornerOdds ? fixtureFields.T1CornerOdds : null,
          status: fixtureFields.T1CornerPredict
            ? fixtureFields.T1CornerResult
              ? fixtureFields.T1CornerResult === "True"
                ? "Won"
                : "Lost"
              : "Not updated yet"
            : null,
        },
        {
          name: "Goals",
          prediction: fixtureFields.T1GoalUOPredict
            ? fixtureFields.T1GoalUOPredict < 0
              ? `Under ${Math.abs(fixtureFields.T1GoalUOPredict)}`
              : `Over ${Math.abs(fixtureFields.T1GoalUOPredict)}`
            : null,
          odds: fixtureFields.T1GoalUOOdds ? fixtureFields.T1GoalUOOdds : null,
          status: fixtureFields.T1GoalUOPredict
            ? fixtureFields.HomeGoals
              ? fixtureFields.T1GoalUOPredict < 0
                ? Number(fixtureFields.HomeGoals) <
                  Math.abs(fixtureFields.T1GoalUOPredict)
                  ? "Won"
                  : "Lost"
                : Number(fixtureFields.HomeGoals) >
                  Math.abs(fixtureFields.T1GoalUOPredict)
                ? "Won"
                : "Lost"
              : "Not updated yet"
            : null,
        },
        {
          name: "Yellow Cards",
          prediction: fixtureFields.T1YellowPredict,
          odds: fixtureFields.T1YellowOdds ? fixtureFields.T1YellowOdds : null,
          status: fixtureFields.T1YellowPredict
            ? fixtureFields.T1YellowResult
              ? fixtureFields.T1YellowResult === "True"
                ? "Won"
                : "Lost"
              : "Not updated yet"
            : null,
        },
      ]
    : [];

  const TABLE_ROWS2 = fixtureFields
    ? [
        {
          name: "Corner Kicks",
          prediction: fixtureFields.T2CornerPredict1,
          odds: fixtureFields.T2CornerOdds ? fixtureFields.T2CornerOdds : null,
          status: fixtureFields.T2CornerPredict
            ? fixtureFields.T2CornerResult
              ? fixtureFields.T2CornerResult === "True"
                ? "Won"
                : "Lost"
              : "Not updated yet"
            : null,
        },
        {
          name: "Goals",
          prediction: fixtureFields.T2GoalUOPredict
            ? fixtureFields.T2GoalUOPredict < 0
              ? `Under ${Math.abs(fixtureFields.T2GoalUOPredict)}`
              : `Over ${Math.abs(fixtureFields.T2GoalUOPredict)}`
            : null,
          odds: fixtureFields.T2GoalUOOdds ? fixtureFields.T2GoalUOOdds : null,
          status: fixtureFields.T2GoalUOPredict
            ? fixtureFields.AwayGoals
              ? fixtureFields.T2GoalUOPredict < 0
                ? Number(fixtureFields.AwayGoals) <
                  Math.abs(fixtureFields.T2GoalUOPredict)
                  ? "Won"
                  : "Lost"
                : Number(fixtureFields.AwayGoals) >
                  Math.abs(fixtureFields.T2GoalUOPredict)
                ? "Won"
                : "Lost"
              : "Not updated yet"
            : null,
        },
        {
          name: "Yellow Cards",
          prediction: fixtureFields.T2YellowPredict,
          odds: fixtureFields.T2YellowOdds ? fixtureFields.T2YellowOdds : null,
          status: fixtureFields.T2YellowPredict
            ? fixtureFields.T2YellowResult
              ? fixtureFields.T2YellowResult === "True"
                ? "Won"
                : "Lost"
              : "Not updated yet"
            : null,
        },
      ]
    : [];

  const TABLE_ROWSTotal = fixtureFields
    ? [
        {
          name: "Corner Kicks",
          prediction: fixtureFields.TCornerPredict1,
          odds: fixtureFields.TCornerOdds ? fixtureFields.TCornerOdds : null,
          status: fixtureFields.TCornerPredict
            ? fixtureFields.TCornerResult
              ? fixtureFields.TCornerResult === "True"
                ? "Won"
                : "Lost"
              : "Not updated yet"
            : null,
        },
        {
          name: "Goals",
          prediction: fixtureFields.TGoalUOPredict
            ? fixtureFields.TGoalUOPredict < 0
              ? `Under ${Math.abs(fixtureFields.TGoalUOPredict)}`
              : `Over ${Math.abs(fixtureFields.TGoalUOPredict)}`
            : null,
          odds: fixtureFields.TGoalUOOdds ? fixtureFields.TGoalUOOdds : null,
          status: fixtureFields.TGoalUOPredict
            ? fixtureFields.AwayGoals && fixtureFields.HomeGoals
              ? fixtureFields.TGoalUOPredict < 0
                ? Number(fixtureFields.AwayGoals) +
                    Number(fixtureFields.HomeGoals) <
                  Math.abs(fixtureFields.TGoalUOPredict)
                  ? "Won"
                  : "Lost"
                : Number(fixtureFields.AwayGoals) +
                    Number(fixtureFields.HomeGoals) >
                  Math.abs(fixtureFields.TGoalUOPredict)
                ? "Won"
                : "Lost"
              : "Not updated yet"
            : null,
        },
        {
          name: "Yellow Cards",
          prediction: fixtureFields.TYellowPredict,
          odds: fixtureFields.TYellowOdds ? fixtureFields.TYellowOdds : null,
          status: fixtureFields.TYellowPredict
            ? fixtureFields.TYellowResult
              ? fixtureFields.TYellowResult === "True"
                ? "Won"
                : "Lost"
              : "Not updated yet"
            : null,
        },
      ]
    : [];

  return (
    <Dialog
      open={open}
      handler={handleOpen}
      className="bg-lightSecondary dark:bg-primary max-h-[90vh] overflow-auto scroll-hidden"
      size="lg"
    >
      <DialogHeader className="text-black dark:text-white text-center">
        <div className="w-full flex justify-between items-center">
          <p> Our Prediction</p>
          <div>
            <button
              className=" bg-secondary/10 text-secondary dark:bg-lightPrimary/10 dark:text-lightPrimary p-2 w-12 h-12 rounded-full flex items-center justify-center"
              onClick={handleOpen}
            >
              <MdOutlineClose />
            </button>
          </div>
        </div>
      </DialogHeader>
      <DialogBody className="p-2 md:p-10 md:py-6">
        <div className="lg:px-6 px-2 py-6 shadow bg-lightSecondary dark:bg-lightPrimary/10 rounded-xl">
        {/* top part of the modal */}
        <div className="flex justify-around items-center gap-4">
            <div className="flex flex-col justify-center items-center gap-2">
              <img
                className="lg:w-14 w-8 h-8 lg:h-14"
                src={fixtureFields?.T1Logo}
                alt={`${fixtureFields?.HomeTeam} logo`}
              />
              <p className="font-bold text-xs md:text-lg text-center text-secondary dark:text-lightPrimary">
                {fixtureFields?.HomeTeam}
              </p>
            </div>

            <div>
              <p className="text-lg md:text-4xl font-semibold text-secondary dark:text-lightPrimary">
                {fixtureFields?.Time}
              </p>
              <p className="text-xs md:text-base text-secondary dark:text-lightPrimary">
                {fixtureFields?.Date}
              </p>
            </div>

            <div className="flex flex-col justify-center items-center gap-2">
              <img
                className="lg:w-14 w-8 h-8 lg:h-14"
                src={fixtureFields?.T2Logo}
                alt={`${fixtureFields?.AwayTeam} logo`}
              />
              <p className="font-bold text-xs md:text-lg  text-center text-secondary dark:text-lightPrimary">
                {fixtureFields?.AwayTeam}
              </p>
            </div>
          </div>

          <div className="w-full h-[1px] dark:bg-secondary/50 my-4"></div>

          {/* Home team part */}
          <div>
            <h3 className="font-bold lg:text-lg text-gray-900 dark:text-gray-400 pl-4">
              {fixtureFields?.HomeTeam}
            </h3>

            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th key={head} className=" pt-0 px-4 pb-4">
                      <Typography
                        variant="small"
                        className="font-normal leading-none opacity-70 dark:text-gray-100 text-black"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {TABLE_ROWS.map(({ name, prediction, odds, status }, index) => {
                  const isLast = index === TABLE_ROWS.length - 1;
                  const classes = isLast ? "px-4 py-1" : "px-4 py-1";

                  return (
                    <tr key={name}>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          className="font-normal opacity-70 dark:text-gray-100 text-black"
                        >
                          {name}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          className="font-normal opacity-70 dark:text-gray-100 text-black"
                        >
                          {prediction || ""}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          className="font-normal opacity-70 dark:text-gray-100 text-black"
                        >
                          {odds ? Number(odds).toFixed(2) : ""}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          className={
                            status === "Won"
                              ? "dark:text-green-700 text-green-900 font-semibold"
                              : status === "Lost"
                              ? "dark:text-red-700 text-red-900 font-semibold"
                              : status === "Not updated yet"
                              ? "dark:text-yellow-700 text-yellow-900 font-semibold"
                              : "font-normal opacity-70 dark:text-gray-100 text-black"
                          }
                        >
                          {status || ""}
                        </Typography>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="w-full h-[1px] dark:bg-secondary/50 my-4"></div>

          {/* Away team part */}
          <div>
            <h3 className="font-bold lg:text-lg text-gray-900 dark:text-gray-400 pl-4 pt-6">
              {fixtureFields?.AwayTeam}
            </h3>
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th key={head} className=" pt-0 px-4 pb-4">
                      <Typography
                        variant="small"
                        className="font-normal leading-none opacity-70 dark:text-gray-100 text-black"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {TABLE_ROWS2.map(
                  ({ name, prediction, odds, status }, index) => {
                    const isLast = index === TABLE_ROWS.length - 1;
                    const classes = isLast ? "px-4 py-1" : "px-4 py-1";

                    return (
                      <tr key={name}>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            className="font-normal opacity-70 dark:text-gray-100 text-black"
                          >
                            {name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            className="font-normal opacity-70 dark:text-gray-100 text-black"
                          >
                            {prediction || ""}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            className="font-normal opacity-70 dark:text-gray-100 text-black"
                          >
                            {odds ? Number(odds).toFixed(2) : ""}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            className={
                              status === "Won"
                                ? "dark:text-green-700 text-green-900 font-semibold"
                                : status === "Lost"
                                ? "dark:text-red-700 text-red-900 font-semibold"
                                : status === "Not updated yet"
                                ? "dark:text-yellow-700 text-yellow-900 font-semibold"
                                : "font-normal opacity-70 dark:text-gray-100 text-black"
                            }
                          >
                            {status || ""}
                          </Typography>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>

          <div className="w-full h-[1px] dark:bg-secondary/50 my-4"></div>

          {/* Total part */}
          <div>
            <h3 className="font-bold lg:text-lg text-gray-900 dark:text-gray-400 pl-4 pt-6">
              Total
            </h3>
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th key={head} className=" pt-0 px-4 pb-4">
                      <Typography
                        variant="small"
                        className="font-normal leading-none opacity-70 dark:text-gray-100 text-black"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {TABLE_ROWSTotal.map(
                  ({ name, prediction, odds, status }, index) => {
                    const isLast = index === TABLE_ROWS.length - 1;
                    const classes = isLast ? "px-4 py-1" : "px-4 py-1";

                    return (
                      <tr key={name}>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            className="font-normal opacity-70 dark:text-gray-100 text-black"
                          >
                            {name || ""}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            className="font-normal opacity-70 dark:text-gray-100 text-black"
                          >
                            {prediction || ""}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            className="font-normal opacity-70 dark:text-gray-100 text-black"
                          >
                            {odds ? Number(odds).toFixed(2) : ""}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            className={
                              status === "Won"
                                ? "dark:text-green-700 text-green-900 font-semibold"
                                : status === "Lost"
                                ? "dark:text-red-700 text-red-900 font-semibold"
                                : status === "Not updated yet"
                                ? "dark:text-yellow-700 text-yellow-900 font-semibold"
                                : "font-normal opacity-70 dark:text-gray-100 text-black"
                            }
                          >
                            {status || ""}
                          </Typography>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default HandicapModalV2;
