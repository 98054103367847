import React from "react";
import {
  useFinishedMatches,
  useHandicapAccuracy,
} from "../../hooks/useAccuracy";
import HandicapNeedleChart from "./HandicapNeedleChart";
import HandicapOddsChart from "./HandicapOddsChart";
import HandicapResultCard from "./HandicapResultCard";
import HandicapFinishedMatchesTable from "./HandicapFinishedMatchesTable";
import HandicapModalV2 from "./HandicapModalV2";

const HandicapAccuracy = () => {
  const [days, setDays] = React.useState("2");
  const [page, setPage] = React.useState(1);
  const limit = 10;
  const [resultData, setResultData] = React.useState();
  const [resultOpen, setResultOpen] = React.useState(false);

  const { data, isLoading } = useHandicapAccuracy(days);
  const { data: finishedMatches, isLoading: finishedLoading } =
    useFinishedMatches();

  const handleResult = (row) => {
    setResultData(row);
    setResultOpen(true);
  };

  // finished matches table columns
  const columns = [
    {
      field: "",
      headerName: "Date and Time",
      render: (row) => (
        <div>
          <p>{row?.Date}</p>
          <p>{row?.Time}</p>
        </div>
      ),
    },
    {
      field: "",
      headerName: "Teams",
      render: (row) => {
        return (
          <div className="flex items-center">
            <div className="w-8 h-8 border border-slate-800 rounded-full">
              <img src={row?.T1Logo} alt="" className=" object-cover w-8 h-8" />
            </div>
            <div className="ml-[-4px] w-8 h-8 border border-slate-300 rounded-full">
              <img src={row?.T2Logo} alt="" className=" object-cover w-8 h-8" />
            </div>
            <div className="ml-2">
              <p>{row?.Matches}</p>
            </div>
          </div>
        );
      },
    },
    {
      field: "LeagueName",
      headerName: "League/Event",
    },
    {
      field: "",
      headerName: "Our Prediction",
      render: (row) => (
        <div>
          <button
            className="border border-primary uppercase w-full text-black font-semibold text-center py-1.5 text-xs rounded-md hover:bg-primary hover:text-white z-10"
            onClick={() => handleResult(row)}
          >
            Get Result
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="mt-1 md:mt-6 px-2 md:px-6">
      <div className="bg-secondary w-full rounded-xl grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-7 gap-4 p-1 lg:p-4">
        <div className="col-span-1 lg:col-span-2 w-full bg-second p-5 rounded-xl flex flex-col justify-center items-center gap-3 bg-white/90">
          <h3 className="text-primary text-2xl font-semibold">Accuracy</h3>
          {/* need chart component */}
          <HandicapNeedleChart
            isLoading={isLoading}
            accuracyData={data?.data}
          />
        </div>

        {/* odds */}
        <div className="col-span-1 lg:col-span-2 bg-second p-5 rounded-xl flex flex-col justify-center items-center gap-3 bg-white/90">
          <h3 className="text-primary text-2xl font-semibold">Average odds</h3>
          {/* odds chart */}
          <HandicapOddsChart isLoading={isLoading} accuracyData={data?.data} />
        </div>

        {/* day selection buttons */}
        <div className="col-span-1 lg:col-span-2 xl:col-span-1 bg-second py-5 rounded-xl flex flex-col justify-center items-stretch px-20 md:px-60 lg:px-2 gap-3 bg-white/90">
          {/* yesterday */}
          <button
            onClick={() => setDays(2)}
            className={`w-full px-4 py-1 font-medium rounded ${
              days === 2
                ? "bg-primary/80 text-white"
                : "bg-[#dadada] text-black hover:bg-primary/10 duration-300"
            }`}
          >
            Yesterday
          </button>
          {/* 7 days */}
          <button
            onClick={() => setDays(7)}
            className={`px-4 py-1 rounded ${
              days === 7
                ? "bg-primary/80 text-white"
                : "bg-[#dadada] text-black hover:bg-primary/10 duration-300"
            }`}
          >
            7 days
          </button>
          {/* 15 days */}
          <button
            onClick={() => setDays(15)}
            className={`px-4 py-1 rounded ${
              days === 15
                ? "bg-primary/80 text-white"
                : "bg-[#dadada] text-black hover:bg-primary/10 duration-300"
            }`}
          >
            15 days
          </button>
          {/* 30 days */}
          <button
            onClick={() => setDays(30)}
            className={`px-4 py-1 rounded ${
              days === 30
                ? "bg-primary/80 text-white"
                : "bg-[#dadada] text-black hover:bg-primary/10 duration-300"
            }`}
          >
            30 days
          </button>
          {/* 90 days */}
          <button
            onClick={() => setDays(90)}
            className={`px-4 py-1 rounded ${
              days === 90
                ? "bg-primary/80 text-white"
                : "bg-[#dadada] text-black hover:bg-primary/10 duration-300"
            }`}
          >
            90 days
          </button>
        </div>

        {/* result section */}
        <div className="col-span-1 lg:col-span-2 bg-second p-5 rounded-xl flex flex-col justify-center items-center gap-3 bg-white/90">
          <h3 className="text-primary text-2xl font-semibold">Results</h3>

          <HandicapResultCard
            isLoading={isLoading}
            days={days}
            accuracyData={data?.data}
          />
        </div>
      </div>

      <div className="mt-3 bg-secondary p-4 rounded-xl">
        <HandicapFinishedMatchesTable
          isLoading={finishedLoading}
          rows={
            finishedMatches?.data
              ?.slice((page - 1) * limit, limit * page)
              ?.map((item, index) => ({
                recordId: item?.id,
                Serial: index + 1 + (page - 1) * limit,
                ...item?.fields,
              })) || []
          }
          columns={columns}
          total={finishedMatches?.data?.length}
          pagination={true}
          paginationOptions={{
            page,
            setPage,
            limit,
          }}
        />
      </div>

      <HandicapModalV2
        open={resultOpen}
        handleOpen={() => setResultOpen(!resultOpen)}
        data={resultData}
      />
    </div>
  );
};

export default HandicapAccuracy;
