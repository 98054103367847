import { Button, IconButton, Navbar } from "@material-tailwind/react";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo/newLogo.png";
import logo2 from "../../assets/images/logo/newLogo.png";
import MobileMenu from "./MobileMenu";
import DarkMode from "./moon/DarkMode";
import { useDarkMode } from "./moon/DarkModeContext";

export default function Header({ onSearch }) {
  const user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;
  const [openNav, setOpenNav] = useState(false);
  const navigate = useNavigate();
  const { darkMode } = useDarkMode();

  const isAdmin =
    user?.fields?.Is_staff_member || user?.fields?.Role === "admin";

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleLogOut = () => {
    Cookies.remove("user");
    Cookies.remove("token");
    Cookies.remove("email");
    Cookies.remove("kodeend_token");
    navigate("/login");
  };

  return (
    <div className="pt-2">
      <Navbar className="z-10 py-4 lg:py-3 px-4 bg-lightPrimary dark:bg-primary border-none relative">
        <div className="mx-auto flex items-center justify-between text-white">
          <Link to="/">
            <img
              onClick={() => handleItemClick("item0")}
              className={`w-28 lg:w-44 ${selectedItem === "item0" ? "" : ""}`}
              src={darkMode ? logo : logo2}
              alt="logo"
            />
          </Link>

          <div className="hidden lg:flex items-center gap-4 text-xl">
            {/* <div className="flex items-center justify-end w-40">
              <button className="" onClick={toggleSearchBar}>
                {isVisible ? (
                  <RxCross2 className="text-black dark:text-white" />
                ) : (
                  <MdOutlineSearch className="text-black dark:text-white" />
                )}
              </button>
              {isVisible && (
                <div className="flex items-center">
                  <input
                    type="text"
                    placeholder="Search..."
                    className=" px-4 absolute right-52 text-xs text-secondary bg-[#cfd8dc] placeholder:text-primary rounded h-8 focus:outline-none  "
                    value={searchTerm}
                    onChange={handleInputChange}
                  />
                </div>
              )}
            </div> */}
            <DarkMode id="darkMode1" />
            <Link to="https://www.betterreturn.net">
              <Button
                variant="outlined"
                color="blue"
                size="sm"
                className="dark:text-white"
              >
                Back to Home
              </Button>
            </Link>
            {isAdmin ? (
              <Link to="/admin">
                <Button
                  variant="outlined"
                  color="blue"
                  size="sm"
                  className="dark:text-white"
                >
                  View as Admin
                </Button>
              </Link>
            ) : null}

            <Button color="deep-orange" size="sm" onClick={handleLogOut}>
              LogOut
            </Button>
          </div>

          <div className="flex lg:hidden items-center gap-2">
            {isAdmin ? (
              <Link to="/admin">
                <Button variant="outlined" color="blue" size="sm">
                  View as Admin
                </Button>
              </Link>
            ) : null}
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-black dark:text-white"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </IconButton>
          </div>
        </div>
      </Navbar>
      <MobileMenu
        open={openNav}
        onClose={() => setOpenNav(false)}
        handleLogOut={handleLogOut}
        // darkMode={darkMode}
        // handleModeChange={handleModeChange}
      />
    </div>
  );
}
