import React from "react";
import { Outlet } from "react-router-dom";

import TrendingNews from "../components/news/TrendingNews";
import AllMatchesTopComponent from "../components/allMatches/AllMatchesTopComponent";


const AllMatches = () => {
  return (
    <div className="">
      <div className="basis-12/12 md:basis-9/12">
        {/* <AllMatchesTopComponent /> */}
        <Outlet />
      </div>
      {/* <div className="hidden basis-0 md:block md:basis-3/12">
        <TrendingNews />
      </div> */}
    </div>
  );
};

export default AllMatches;
