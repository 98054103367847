import { Button, Card, CardBody, Tab, Tabs, TabsHeader } from "@material-tailwind/react";
import React, { useState } from "react";
import {
  Area,
  Bar,
  ComposedChart,
  Label,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Loading from "../components/shared/Loading/Loading";
import { useROI } from "../hooks/useROI";
import formatDate from "../utils/formatter";

// {{base}}/api/roi?startedDate=2023-11-30&endDate=2023-11-01&initialBalance=1000&percent=15&game=soccer

const TABS = [
  {
    id: 1,
    label: "Soccer",
    value: "soccer",
  },
  // {
  //   id: 2,
  //   label: "CSGO",
  //   value: "csgo",
  // },
  {
    id:2,
    label: "Cricket",
    value: "cricket",
  },
  {
    id: 3,
    label: "Valorant",
    value: "valorant",
  },
];

const ROI = () => {
  const [selected, setSelected] = useState("soccer");
  const [filter, setFilter] = useState(false);
  const [stateData, setStateData] = useState({
    // startDate subtract 30 days from today
    startDate: formatDate(
      new Date(new Date().setDate(new Date().getDate() - 30))
    ),
    endDate: formatDate(new Date()),
    initialBalance: 1000,
    percent: 15,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStateData({ ...stateData, [name]: value });
  };

  const { data, isLoading } = useROI({
    ...stateData,
    startDate: stateData?.startDate,
    endDate: stateData?.endDate,
    // isSkip,
    filter,
    game: selected
  });

  const handleSubmit = async () => {
    setFilter(true);
  };

  const handleTabClick = (value) => {
    setSelected(value);
  };


  const chartData = data?.dataArray?.map((item, index) => {
    let barColor = "#413ea0";

    if (index > 0 && item.finalBalance < data?.dataArray[index - 1].finalBalance) {
      barColor = "#a80505";
    }

    return {
      name: item.date,
      finalBalance: item.finalBalance,
      fill: barColor,
    };
  });

  return (
    <Card className="bg-lightPrimary dark:bg-primary h-[calc(100vh-162px)] overflow-auto scroll-hidden">
      <CardBody className="p-2 md:p-6 text-black dark:text-white">
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold">ROI Calculator</h2>
          <p>Calculation of your Investment</p>
        </div>

        <Tabs
          value={selected}
          className="w-full md:w-max scroll-hidden text-black overflow-x-scroll flex flex-col lg:flex-row lg:gap-36 gap-4"
        >
          <TabsHeader className="dark:bg-secondary overflow-x-scroll scroll-hidden bg-lightSecondary text-black w-full">
            {TABS.map(({ label, value }) => (
              <Tab
                onClick={() => handleTabClick(value)}
                key={value}
                value={value}
                className="text-gray-600 "
              >
                &nbsp;&nbsp;{label}&nbsp;&nbsp;
              </Tab>
            ))}
          </TabsHeader>
        </Tabs>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
          {/* Left Side */}
          <Card className=" bg-lightSecondary dark:bg-secondary shadow-lg ">
            <CardBody className="p-4 md:p-6 text-black dark:text-white">
              <h3 className="mb-8 text-xl font-semibold">
                Fill out these fields
              </h3>

              <div className="flex items-center justify-between gap-4 my-4">
                <p>Start Date: </p>

                <input
                  type="date"
                  name="startDate"
                  onChange={handleInputChange}
                  value={stateData?.startDate}
                  className={`bg-lightPrimary text-black  dark:text-white dark:datepicker dark:bg-primary px-2.5 text-xs py-2 rounded-lg w-40`}
                />
              </div>

              <div className="flex items-center justify-between gap-4">
                <p>End Date: </p>

                <input
                  type="date"
                  name="endDate"
                  onChange={handleInputChange}
                  value={stateData?.endDate}
                  className={`bg-lightPrimary text-black  dark:text-white dark:datepicker dark:bg-primary px-2.5 text-xs py-2 rounded-lg w-40 outline:red-500 focus:border-0`}
                />
              </div>

              <div className="flex items-center justify-between gap-4 my-4">
                <p>Initial Wallet/Capital: </p>

                <input
                  type="number"
                  name="initialBalance"
                  onChange={handleInputChange}
                  value={stateData?.initialBalance}
                  className={`bg-lightPrimary text-black  dark:text-white dark:datepicker dark:bg-primary px-2.5 text-xs py-2 rounded-lg w-40`}
                />
              </div>

              <div className="flex items-center justify-between gap-4 my-4">
                <p>Investment (%): </p>

                <input
                  type="number"
                  name="percent"
                  onChange={handleInputChange}
                  value={stateData?.percent}
                  max={99}
                  className={`bg-lightPrimary text-black  dark:text-white dark:datepicker dark:bg-primary px-2.5 text-xs py-2 rounded-lg w-40`}
                />
              </div>

              {/* {!isSkip ? ( */}
              <div className="flex items-center justify-between gap-4 mt-8">
                <h3 className="text-lg font-semibold">Possible Outcome: </h3>

                <h3 className="text-lg font-bold text-green-500">
                  {data?.roi
                    ? Number(data?.roi).toFixed(2) || "$00.00"
                    : "Calculating..."}
                </h3>
              </div>
              {/* ) : null} */}

              {/* <div className={`flex items-center justify-end gap-4 mt-8 `}>
                <Button
                  className="dark:bg-white dark:text-black bg-black text-white"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? "Calculating..." : "Calculate"}
                </Button>
              </div> */}
            </CardBody>
          </Card>

          {/* Right Side */}
          <Card className="w-full bg-lightSecondary dark:bg-secondary shadow-lg">
            <CardBody className="p-4 md:p-6 text-black dark:text-white">
              <h3 className="mb-8 text-xl font-semibold">
                Calculation Summary
              </h3>

              <p className="mb-2">Selected days : {" "}
                <span className="text-green-500 font-bold">
                  {data?.dataArray?.length}
                </span>
              </p>
              <p className="mb-2">Investment Amount: {" "}
                <span className="text-green-500 font-bold">
                  {stateData?.initialBalance}
                </span>
              </p>
              <p className="mb-2">Total profit :{" "}
                <span className="text-green-500 font-bold">
                  {data?.roi
                    ? Number(data?.roi - stateData?.initialBalance).toFixed(2)
                    : null
                  }
                </span>
              </p>
              <p> Profit (%):{" "}
                <span className="text-green-500 font-bold">
                  {data?.roi
                    ? Number((data?.roi - stateData?.initialBalance) * 100 / stateData?.initialBalance).toFixed(2)
                    : null
                  }
                </span>
              </p>
            </CardBody>
          </Card>
        </div>
        {isLoading ? (
          <Loading className="h-40" />
        ) : (
          <Card className="w-full bg-lightSecondary dark:bg-secondary shadow-lg mt-6">
            <CardBody className="p-4 md:p-0 text-black dark:text-white">
              <ResponsiveContainer width="100%" height={300}>
                <ComposedChart
                  data={chartData}
                  margin={{ top: 150, right: 80, bottom: 20, left: 20 }}
                >
                  {/* <CartesianGrid stroke="#f5f5f5" /> */}
                  <XAxis dataKey="name">
                    <Label value="Date" offset={0} position="bottom" />
                  </XAxis>
                  <YAxis
                    label={{ value: "Amount", angle: -90, position: "left" }}
                  />
                  <Tooltip />
                  {/* <Legend /> */}
                  <Area
                    type="monotone"
                    dataKey="amt"
                    fill="#8884d8"
                    stroke="#8884d8"
                  />
                  <Bar dataKey="finalBalance" barSize={15} fill="#413ea0" />
                  <Line
                    type="monotone"
                    dataKey="finalBalance"
                    stroke="#ff7300"
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </CardBody>
          </Card>
        )}
      </CardBody>
    </Card>
  );
};

export default ROI;
