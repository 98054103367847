const AccuracyResultCard = ({ isLoading, accuracyData, game }) => {
    return (
      <div className="w-full flex justify-center items-center">
        {isLoading ? (
          <span className="loading loading-spinner text-gray loading-md h-[145px]"></span>
        ) : (
          <div className="flex flex-col justify-center items-center gap-2 mt-3 w-full">
            <p className="text-primary font-medium text-[17px]">
              Total predictions:{" "}
              <span className="text-primary font-semibold">
                {accuracyData?.total}
              </span>
            </p>
            <p className="text-primary font-medium text-[17px]">
              Win:{" "}
              <span className="text-primary font-semibold">
                {accuracyData?.win}
              </span>
            </p>
            <p className="text-primary font-medium text-[17px]">
              Lost:{" "}
              <span className="text-primary font-semibold">
                {accuracyData?.lost}
              </span>
            </p>
            {/* average odds */}
            {accuracyData?.avgOdds === "NaN" ? (
              <p className="text-primary font-medium text-[17px]">
                Average odds:{" "}
                <span className="text-primary font-semibold">0.00</span>
              </p>
            ) : (
              <p className="text-primary font-medium text-[17px]">
                Average odds:{" "}
                <span className="text-primary font-semibold">
                  {accuracyData?.avgOdds}
                </span>
              </p>
            )}
            {/* win percentage */}
            {accuracyData?.winParc === "NaN" ? (
              <p className="text-primary_light font-medium text-[17px]">
                Win: <span className="text-primary font-semibold">0.00</span>
              </p>
            ) : (
              <p className="text-primary font-medium text-[17px]">
                Win:{" "}
                <span className="text-primary font-semibold">
                  {accuracyData?.winParc}%
                </span>
              </p>
            )}
          </div>
        )}
      </div>
    );
  };
  
  export default AccuracyResultCard;
  