import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";


const useNewsletterSubscribers = () => {

    const { isLoading: subscribersLoading, data: allSubscribers, refetch: subscribersRefetch } = useQuery({
        queryKey: ["newsletter-subscribers"],
        queryFn: async () => {
            const res = await fetcher.get("/api/subscriber");
            return res.data;
        }
    })

    return { subscribersLoading, allSubscribers, subscribersRefetch };
};

export default useNewsletterSubscribers;