import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";

export const useSingleUser = ({ access_token }) => {
  return useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      const response = await fetcher.get("/api/user/get");
      return response.data;
    },
    enabled: !!access_token,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
};

export const usePaidUsers = ({ search }) => {
  return useQuery({
    queryKey: ["paid-users", search],
    queryFn: async () => {
      const response = await fetcher.get("/api/user/paid-users", {
        params: { search },
      });
      return response.data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
};
