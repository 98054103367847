import axios from "axios";
import Cookies from "js-cookie";

  // login for chatbot leads
  export const kodeendLogin = async () => {
    const data = {
      email: "admin@betterreturn.net",
      password: "Admin1234!",
    };

    try {
      const res = await axios.post(
        "https://api.kodeend.com/api/v1/auth/login",
        data
      );
      Cookies.set("kodeend_token", res?.data?.data?.token, { expires: 7 });
    } catch (error) {
      return Promise.reject(
        error?.response?.data?.message || "Something Wen't wrong"
      );
    }
  };