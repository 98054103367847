import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";


const useAllBlogs = () => {

    const { isLoading: allBlogsLoading, data: allBlogs, refetch: allBlogsRefetch } = useQuery({
        queryKey: ["all-blogs"],
        queryFn: async () => {
            const res = await fetcher("/api/blogs?draft=true");
            return res.data
        }
    })

    return { allBlogsLoading, allBlogs, allBlogsRefetch };
};

export default useAllBlogs;