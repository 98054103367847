import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";
import { currentDate, currentTime, userTimeZone } from "../utils/timeZone";

export const useAccuracy = ({ game, search }) => {
  return useQuery({
    queryKey: ["accuracy", game, search],
    queryFn: async () => {
      const response = await fetcher.get(
        `/api/search/game?search=${search}&game=${game}&timeZone=${userTimeZone}`
      );

      const sortedData = response?.data?.data?.sort((a, b) => {
        const dateA = new Date(`${a.fields.Date} ${a.fields.Time}`);
        const dateB = new Date(`${b.fields.Date} ${b.fields.Time}`);
        return dateB - dateA;
      });
      return sortedData;
    },

    enabled: !!game,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

// export const useAccuracyChart = ({ days }) => {
//   return useQuery({
//     queryKey: ["accuracyChart", days],
//     queryFn: async () => {
//       const response = await fetcher.get(`/api/accuracy?days=${days}`, {
//         params: {
//           date: currentDate,
//           time: currentTime,
//           zone: userTimeZone,
//         },
//       });

//       return response.data;
//     },

//     refetchOnMount: false,
//     refetchOnWindowFocus: false,
//     keepPreviousData: true,
//   });
// };


// version 2

export const useAccuracyChart = ({ days, filter }) => {
  return useQuery({
    queryKey: ["accuracyChart", days, filter],
    queryFn: async () => {
      const response = await fetcher.get('/api/v2/accuracy', {
        params: {
          days,
          date: new Date().toISOString(),
          zone: userTimeZone,
          filter
        },
      });

      return response.data;
    },

    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useHandicapAccuracy = (days) => {
  return useQuery({
    queryKey: ["handicapAccuracy", days],
    queryFn: async () => {
      const response = await fetcher.get("/api/handicap/v2/accuracy", {
        params: {
          days,
          date: new Date().toISOString(),
          zone: userTimeZone,
        },
      });

      return response.data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useFinishedMatches = () => {
  return useQuery({
    queryKey: ["finishedMatches"],
    queryFn: async () => {
      const response = await fetcher.get("/api/handicap/v2/finished", {
        params: {
          date: new Date().toISOString(),
          zone: userTimeZone,
        },
      });

      return response.data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
