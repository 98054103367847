import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../components/shared/Header";
import LeftBar from "../components/shared/LeftBar";
import Loading from "../components/shared/Loading/Loading";
import SubHeader from "../components/shared/SubHeader";
import { useSingleUser } from "../hooks/useUser";

const Main = () => {
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const token = Cookies.get("token");
  const user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

  const { data, isSuccess } = useSingleUser({
    access_token: token,
  });

  useEffect(() => {
    if (!token || !user) {
      navigate("/login");
    }

    if (isSuccess) {
      Cookies.set("user", JSON.stringify(data?.data), { expires: 7 });
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [isSuccess, data, token, user, navigate]);

  return (
    <>
      {isLoading ? (
        <Loading className="h-screen bg-lightSecondary dark:bg-secondary" />
      ) : (
        <div className="max-w-screen-2xl h-screen overflow-hidden mx-auto z-0 px-2">
          <Header />
          <div className="mt-2 lg:flex gap-2 h-[calc(100vh - 70px)]">
            <div className="basis-0 hidden lg:block lg:basis-2/12 ">
              <LeftBar />
            </div>

            <div className="basis-12/12 lg:basis-10/12">
              <div className="flex flex-col gap-2">
                <SubHeader />
                <div>
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Main;
