import { useState } from "react";
import { useAccuracyChart } from "../../hooks/useAccuracy";
import AccuracyNeedleChart from "./AccuracyNeedleChart";
import AccuracyOddsChart from "./AccuracyOddsChart";
import AccuracyResultCard from "./AccuracyResultCard";
import { useAllFinishedMatches } from "../../hooks/useFinishedMatches";
import { IoIosStar } from "react-icons/io";
import FinishedMatchesTable from "./FinishedMatchesTable";
import { Card, CardBody } from "@material-tailwind/react";
import CricketPrediction from "../cricket/CricketPrediction";

const gameTab = [
  { name: "Soccer", value: "soccer" },
  { name: "Cricket", value: "cricket" },
  { name: "Valorant", value: "valorant" },
  { name: "All", value: "all" },
];

const AccuracyV2 = () => {
  const [days, setDays] = useState(2);
  const [date, setDate] = useState();
  const [page, setPage] = useState(1);
  const limit = 10;
  const [filter, setFilter] = useState("soccer");
  const [cricketPrediction, setCricketPrediction] = useState();
  const [cricketPredictionOpen, setCricketPredictionOpen] = useState(false);

  const formattedDate = date ? new Date(date).toISOString() : "";

  const { data, isLoading } = useAccuracyChart({ days, filter });
  const { data: tableData, isLoading: tableLoading } = useAllFinishedMatches({
    game: filter,
    date: formattedDate,
  });

  const handleOpenModal = (row) => {
    setCricketPrediction(row);
    setCricketPredictionOpen(true);
  };

  // finished matches table columns
  const columns = [
    {
      field: "",
      headerName: "Date and Time",
      render: (row) => (
        <div className="flex gap-1.5 items-center">
          <div
            className={`${
              row?.Results?.trim().toLowerCase() === "true"
                ? "bg-green-700"
                : "bg-red-700"
            } w-[3px] h-10`}
          ></div>
          <div className="flex items-center">
            <IoIosStar
              className={`text-xl ${
                row?.Results?.trim().toLowerCase() === "true"
                  ? "text-green-700"
                  : "text-red-700"
              }`}
            />
          </div>
          <div>
            <p>{row?.Date}</p>
            <p>{row?.Time}</p>
          </div>
        </div>
      ),
    },
    {
      field: "",
      headerName: "Teams",
      render: (row) => {
        return (
          <div className="flex items-center">
            <div className="w-8 h-8 border border-slate-800 rounded-full">
              <img src={row?.T1Logo} alt="" className=" object-cover w-8 h-8" />
            </div>
            <div className="ml-[-4px] w-8 h-8 border border-slate-300 rounded-full">
              <img src={row?.T2Logo} alt="" className=" object-cover w-8 h-8" />
            </div>
            <div className="ml-2">
              <p>{row?.Matches}</p>
            </div>
          </div>
        );
      },
    },
    {
      field: "LeagueName",
      headerName: "League/Event",
    },
    {
      field: "",
      headerName: "Our Prediction",
      render: (row) => (
        <div>
          {filter === "cricket" ? (
            <button
              className="border border-primary uppercase w-full text-black font-semibold text-center py-1.5 text-xs rounded-md hover:bg-primary hover:text-white z-10"
              onClick={() => handleOpenModal(row)}
            >
              Get Result
            </button>
          ) : (
            <p className="text-green-700 font-semibold">{row?.Prediction}</p>
          )}
        </div>
      ),
    },
  ];

  return (
    <Card className="bg-lightPrimary dark:bg-primary h-[calc(100vh-152px)]  md:h-[calc(100vh-170px)] py-1 md:py-6">
      <CardBody className="overflow-y-scroll scroll-hidden text-justify text-gray-200 p-0">
        <div className=" px-2 md:px-6">
          <div className="bg-secondary w-full rounded-xl grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-7 gap-4 p-1 lg:p-4">
            <div className="col-span-1 lg:col-span-2 w-full bg-second p-5 rounded-xl flex flex-col justify-center items-center gap-3 bg-white/90">
              <h3 className="text-primary text-2xl font-semibold">Accuracy</h3>
              {/* need chart component */}
              <AccuracyNeedleChart
                isLoading={isLoading}
                accuracyData={data?.data}
              />
            </div>

            {/* odds */}
            <div className="col-span-1 lg:col-span-2 bg-second p-5 rounded-xl flex flex-col justify-center items-center gap-3 bg-white/90">
              <h3 className="text-primary text-2xl font-semibold">
                Average odds
              </h3>
              {/* odds chart */}
              <AccuracyOddsChart
                isLoading={isLoading}
                accuracyData={data?.data}
              />
            </div>

            {/* day selection buttons */}
            <div className="col-span-1 lg:col-span-2 xl:col-span-1 bg-second py-5 rounded-xl flex flex-col justify-center items-stretch px-20 md:px-60 lg:px-2 gap-3 bg-white/90">
              {/* yesterday */}
              <button
                onClick={() => setDays(2)}
                className={`w-full px-4 py-1 font-medium rounded ${
                  days === 2
                    ? "bg-primary/80 text-white"
                    : "bg-[#dadada] text-black hover:bg-primary/10 duration-300"
                }`}
              >
                Yesterday
              </button>
              {/* 7 days */}
              <button
                onClick={() => setDays(7)}
                className={`px-4 py-1 rounded ${
                  days === 7
                    ? "bg-primary/80 text-white"
                    : "bg-[#dadada] text-black hover:bg-primary/10 duration-300"
                }`}
              >
                7 days
              </button>
              {/* 15 days */}
              <button
                onClick={() => setDays(15)}
                className={`px-4 py-1 rounded ${
                  days === 15
                    ? "bg-primary/80 text-white"
                    : "bg-[#dadada] text-black hover:bg-primary/10 duration-300"
                }`}
              >
                15 days
              </button>
              {/* 30 days */}
              <button
                onClick={() => setDays(30)}
                className={`px-4 py-1 rounded ${
                  days === 30
                    ? "bg-primary/80 text-white"
                    : "bg-[#dadada] text-black hover:bg-primary/10 duration-300"
                }`}
              >
                30 days
              </button>
              {/* 90 days */}
              <button
                onClick={() => setDays(90)}
                className={`px-4 py-1 rounded ${
                  days === 90
                    ? "bg-primary/80 text-white"
                    : "bg-[#dadada] text-black hover:bg-primary/10 duration-300"
                }`}
              >
                90 days
              </button>
            </div>

            {/* result section */}
            <div className="col-span-1 lg:col-span-2 bg-second p-5 rounded-xl flex flex-col justify-center items-center gap-3 bg-white/90">
              <h3 className="text-primary text-2xl font-semibold">Results</h3>

              <AccuracyResultCard
                isLoading={isLoading}
                days={days}
                accuracyData={data?.data}
              />
            </div>
          </div>

          {/* game filtering and searching part */}
          <div className="mt-2 py-4 px-2 bg-secondary flex flex-col lg:flex-row justify-between items-center gap-4 rounded-xl">
            <div className="flex justify-center items-center gap-3">
              {gameTab.map((tab) => (
                <button
                  key={tab.value}
                  onClick={() => setFilter(tab.value)}
                  className={`px-3 md:px-4 text-sm md:text-base py-1 rounded ${
                    tab.value === filter
                      ? "bg-primary/80 text-white border border-gray-700"
                      : "bg-[#dadada] text-black hover:bg-primary/10 duration-300"
                  }`}
                >
                  {tab.name}
                </button>
              ))}
            </div>
            <div>
              <input
                type="Date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                placeholder="Select Date"
                className="px-3 py-1 text-sm md:text-base rounded text-black border border-gray-700 focus:outline-none"
              />
            </div>
          </div>

          <div className="mt-2 bg-secondary p-4 rounded-xl">
            <FinishedMatchesTable
              isLoading={tableLoading}
              rows={
                tableData?.data
                  ?.slice((page - 1) * limit, limit * page)
                  ?.map((item, index) => ({
                    recordId: item?.id,
                    Serial: index + 1 + (page - 1) * limit,
                    ...item?.fields,
                  })) || []
              }
              columns={columns}
              total={tableData?.data?.length}
              pagination={true}
              paginationOptions={{
                page,
                setPage,
                limit,
              }}
            />
          </div>
        </div>

        <CricketPrediction
          open={cricketPredictionOpen}
          handleOpen={() => setCricketPredictionOpen(!cricketPredictionOpen)}
          data={cricketPrediction}
        />
      </CardBody>
    </Card>
  );
};

export default AccuracyV2;
