import { ScrollRestoration } from "react-router-dom";
import Loading from "../../components/shared/Loading/Loading";
import useNewsletterSubscribers from "../../hooks/useNewsletterSubscribers";
import DataTable from "../../components/shared/DataTable";
import DeleteDialog from "../../components/admin/DeleteDialog";
import { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { IconButton } from "@material-tailwind/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetcher } from "../../utils/authFetch";
import toast from "react-hot-toast";
import axios from "axios";


const NewsletterLists = () => {

    // hooks
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selected, setSelected] = useState({});
    const [page, setPage] = useState(1);
    const limit = 10;
    const queryClient = useQueryClient();
    // get all the subscription list
    const { subscribersLoading, allSubscribers, subscribersRefetch } = useNewsletterSubscribers();


    // delete an email from list
    const { mutateAsync: deletePost, isLoading: deleteLoading } = useMutation({
        mutationFn: async (email) =>
            axios.delete(`https://api.betterreturn.net/api/subscriber`, { data: { email } })
                .then(res => {
                    queryClient.invalidateQueries(["user"]);
                    subscribersRefetch();
                    toast.success(res?.data?.message || "Email deleted successfully!");
                    setSelected({});
                    setDeleteOpen(false);
                })
                .catch(() => {
                    toast.error("Something went wrong!");
                })
        // onSuccess: (res) => {

        // },
        // onError: (err) => {
        //     toast.error(err?.response?.data?.message || "Something went wrong!");
        // },
    });


    const handleDelete = async () => {
        await deletePost(selected?.Email);
    };

    // conditional loading
    if (subscribersLoading) {
        return <Loading className={"h-[600px]"} />
    }

    const columns = [
        {
            field: "Serial",
            headerName: "Serial",
        },
        {
            field: "Email",
            headerName: "Email list",
            render: (row) => <span className="font-medium text-base w-[300px]">{row?.Email}</span>,
        },
        {
            field: "CreatedAt",
            headerName: "Subscription date",
            render: (row) => <span className="text-sm font-medium w-[300px]">{row?.CreatedAt?.split('T')[0]}</span>,
        },
        {
            field: "action",
            headerName: "Action",
            render: (row) => (
                <span>
                    {/* delete button */}
                    <IconButton
                        title="Delete"
                        color="red"
                        variant="gradient"
                        className="text-white text-md h-8 w-8 rounded-full p-1 ml-3"
                        onClick={() => {
                            setSelected(row);
                            setDeleteOpen(true);
                        }}
                    >
                        <FaTrash />
                    </IconButton>
                </span>
            ),
        },
    ];


    return (
        <div>
            <h2 className="text-3xl font-bold">Newsletter subscribers</h2>


            <div className="mt-6">
                <DataTable
                    isLoading={subscribersLoading}
                    rows={
                        allSubscribers?.data?.records
                            ?.slice((page - 1) * limit, limit * page)
                            ?.map((item, index) => ({
                                recordId: item?.id,
                                Serial: index + 1 + (page - 1) * limit,
                                ...item?.fields,
                            })) || []
                    }
                    columns={columns}
                    total={allSubscribers?.data?.records?.length}
                    pagination={true}
                    paginationOptions={{
                        page,
                        setPage,
                        limit,
                    }}
                />
            </div>
            <ScrollRestoration />


            {/* Delete Dialog */}
            <DeleteDialog
                open={deleteOpen}
                handler={() => setDeleteOpen(!deleteOpen)}
                title="Delete email from list"
                description="Are you sure you want to delete this email?"
                handleDelete={handleDelete}
                loading={deleteLoading}
            />

        </div>
    );
};

export default NewsletterLists;