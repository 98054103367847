

const CreateSeoSection = ({ metaDescription, setMetaDescription, existingData }) => {

    if (existingData === "null") {
        existingData = null
    }

    return (
        <>
            <h3 className="text-3xl font-bold text-black">SEO Section</h3>
            {/* meta description */}
            <div className="w-full flex flex-col justify-start items-start gap-2">
                <label htmlFor="metaDsc" className="font-medium flex justify-start items-center gap-2">
                    Meta description {metaDescription?.length > 160 && <p className="bg-[#ffe7be21] text-orange-700 text-sm px-2 py-0.5 rounded-lg">(You are exceeding 160 characters!)</p>}
                </label>
                <textarea
                    onChange={(e) => setMetaDescription(e.target.value)}
                    value={metaDescription}
                    name="metaDsc"
                    id="metaDsc"
                    placeholder="Enter meta description here"
                    className={`focus:outline-none px-3 py-1 border ${metaDescription?.length > 160 ? "border-yellow-400 focus:border-yellow-600 bg-[#ffffe5]" : "border-gray-300 focus:border-gray-500 bg-white"} transition-all duration-500 rounded-md w-full`}
                />
                <p className="text-xs text-gray-700">{metaDescription?.length} characters</p>
            </div>
        </>
    );
};

export default CreateSeoSection;