import moment from "moment";

// Function to calculate the time until the match starts
export const getTimeUntilMatchStart = (matchDate, matchTime) => {
  const matchDateTime = moment(`${matchDate} ${matchTime}`, "YYYY-MM-DD HH:mm");
  const now = moment();
  const duration = moment.duration(matchDateTime.diff(now));
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  return { hours, minutes };
};
