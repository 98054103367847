import { Dialog, DialogBody, DialogHeader } from "@material-tailwind/react";
import React from "react";
import { MdOutlineClose } from "react-icons/md";

const SoccerMatchDetails = ({ open, handleOpen, data }) => {
  return (
    <Dialog
      open={open}
      handler={handleOpen}
      className=" p-6 bg-lightSecondary dark:bg-primary max-h-[90vh] overflow-auto scroll-hidden"
      size="md"
    >
      <DialogHeader className="text-black dark:text-white text-center">
        <div className="w-full flex justify-between items-center">
          <p>Match Details</p>
          <div>
            <button
              className=" bg-secondary/10 text-secondary dark:bg-lightPrimary/10 dark:text-lightPrimary p-2 w-12 h-12 rounded-full flex items-center justify-center"
              onClick={handleOpen}
            >
              <MdOutlineClose />
            </button>
          </div>
        </div>
      </DialogHeader>
      <DialogBody className="p-2 md:p-4">
        <div className="lg:px-6 px-2 py-6 shadow bg-lightSecondary dark:bg-lightPrimary/10 rounded-xl">
          {/* top part of the code */}
          <div className="flex justify-between items-center gap-4">
            <div className="flex flex-col justify-center items-center gap-2">
              <img
                className="lg:w-14 w-8 h-8 lg:h-14"
                src={data?.T1Logo}
                alt={`${data?.HomeTeam} logo`}
              />
              <p className="font-bold text-xs md:text-lg text-center text-secondary dark:text-lightPrimary">
                {data?.HomeTeam}
              </p>
            </div>

            <div>
              <p className="text-lg md:text-4xl font-semibold text-secondary dark:text-lightPrimary">
                {data?.Time}
              </p>
              <p className="text-xs md:text-base text-secondary dark:text-lightPrimary">
                {data?.Date}
              </p>
              <p
                className={`${
                  data?.status === "Finished"
                    ? "text-orange-600"
                    : data?.status === "Upcoming"
                    ? "text-green-600"
                    : "text-red-600"
                } text-center font-semibold text-xl md:text-2xl`}
              >
                {data?.status}
              </p>
            </div>

            <div className="flex flex-col justify-center items-center gap-2">
              <img
                className="lg:w-14 w-8 h-8 lg:h-14"
                src={data?.T2Logo}
                alt={`${data?.AwayTeam} logo`}
              />
              <p className="font-bold text-xs md:text-lg  text-center text-secondary dark:text-lightPrimary">
                {data?.AwayTeam}
              </p>
            </div>
          </div>

          <div className="w-full h-[1px] dark:bg-secondary/50 my-8"></div>

          {/* start in hours */}
          {(data?.startHours || data?.startMinutes) && (
            <div className="mt-8 flex justify-center items-center bg-primary/10 dark:bg-secondary/30 py-2 rounded-md">
              {data?.startHours > 0 || data?.startMinutes > 0 ? (
                <p className="text-center text-sm font-semibold text-secondary dark:text-lightSecondary">
                  {data?.startHours} hrs {data?.startMinutes} mins to start
                </p>
              ) : data?.Results ? (
                <p className="text-center text-sm font-semibold text-secondary dark:text-lightSecondary">
                  Match has already finished
                </p>
              ) : (
                <p className="text-center text-sm font-semibold text-secondary dark:text-lightSecondary">
                  Match has already started
                </p>
              )}
            </div>
          )}

          <div className="mt-8">
            <h3 className="text-xl md:2xl font-semibold text-green-700">
              Score
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mt-2">
              <div className="flex justify-between border border-secondary/10 bg-primary/5 dark:border-lightSecondary/5 dark:bg-secondary/20 rounded-sm p-2">
                <p className="text-sm md:text-base text-secondary dark:text-lightPrimary font-semibold">
                  {data?.HomeTeam}
                </p>
                <p className="text-xs md:text-sm font-semibold text-secondary dark:text-lightSecondary ">
                  {data?.HomeGoals}
                </p>
              </div>
              <div className="flex justify-between border border-secondary/10 bg-primary/5 dark:border-lightSecondary/5 dark:bg-secondary/20 rounded-sm p-2">
                <p className="text-sm md:text-base text-secondary dark:text-lightPrimary font-semibold">
                  {data?.AwayTeam}
                </p>
                <p className="text-xs md:text-sm font-semibold text-secondary dark:text-lightSecondary">
                  {data?.AwayGoals}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <h3 className="text-xl md:2xl font-semibold text-green-700">
              League & Season
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mt-2">
              <div className="flex justify-between border border-secondary/10 bg-primary/5 dark:border-lightSecondary/5 dark:bg-secondary/20 rounded-sm p-2">
                <p className="text-sm md:text-base text-secondary dark:text-lightPrimary font-semibold">
                  League
                </p>
                <p className="text-xs md:text-sm font-semibold text-secondary dark:text-lightSecondary ">
                  {data?.LeagueName}
                </p>
              </div>
              <div className="flex justify-between border border-secondary/10 bg-primary/5 dark:border-lightSecondary/5 dark:bg-secondary/20 rounded-sm p-2">
                <p className="text-sm md:text-base text-secondary dark:text-lightPrimary font-semibold">
                  Season
                </p>
                <p className="text-xs md:text-sm font-semibold text-secondary dark:text-lightSecondary">
                  {data?.Round}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <h3 className="text-xl md:2xl font-semibold text-green-700">
              City & Country
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mt-2">
              <div className="flex justify-between border border-secondary/10 bg-primary/5 dark:border-lightSecondary/5 dark:bg-secondary/20 rounded-sm p-2">
                <p className="text-sm md:text-base text-secondary dark:text-lightPrimary font-semibold">
                  City
                </p>
                <p className="text-xs md:text-sm font-semibold text-secondary dark:text-lightSecondary ">
                  {data?.City}
                </p>
              </div>
              <div className="flex justify-between border border-secondary/10 bg-primary/5 dark:border-lightSecondary/5 dark:bg-secondary/20 rounded-sm p-2">
                <p className="text-sm md:text-base text-secondary dark:text-lightPrimary font-semibold">
                  Country
                </p>
                <p className="text-xs md:text-sm font-semibold text-secondary dark:text-lightSecondary">
                  {data?.Country}
                </p>
              </div>
            </div>
          </div>

          <div className="card mt-16 px-8 py-6">
            <h4 className="text-sm lg:text-xl text-center text-black dark:text-white">
              Venue :{" "}
              <strong className="font-bold tracking-widest text-green-800 dark:text-green-500">
                {data?.Venue}
              </strong>
            </h4>
          </div>
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default SoccerMatchDetails;
