

const CreateTitle = ({ title, setTitle, emptyFieldError }) => {

    return (
        <input
            onChange={(e) => setTitle(e.target.value)}
            name="blogTitle"
            id="blogTitle"
            value={title}
            placeholder={emptyFieldError ? "Title required" : "Enter title"}
            className={`focus:outline-none w-full py-4 bg-white px-4 rounded-md text-4xl font-bold ${emptyFieldError && !title ? "placeholder-red-600" : "placeholder-gray-700"}`} />
    );
};

export default CreateTitle;