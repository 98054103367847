import {
    Button,
    Card,
    CardBody,
    CardFooter,
    IconButton,
    Typography,
} from "@material-tailwind/react";
import React from "react";
import Loading from "./Loading/Loading";

const DataTable = ({
  isLoading,
  rows,
  columns,
  total,
  pagination,
  paginationOptions: { page, limit = 10, setPage },
}) => {
  //   const { page, limit, setPage } = paginationOptions || {};
  const totalPages = Math.ceil(total / (limit || 10));

  return (
    <>
      <Card className="w-full overflow-hidden rounded-xl">
        <CardBody className="w-full p-0">
          <div className="w-full overflow-x-auto">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {columns?.map((column) => (
                    <th key={column?.field} className="bg-blue-100/40 p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold leading-none text-[#5096ff]"
                      >
                        {column?.headerName}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td
                      colSpan={columns?.length}
                      className="py-40 px-6 text-center"
                    >
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  <>
                    {Number(total) <= 0 ? (
                      <tr>
                        <td
                          colSpan={columns.length}
                          className="py-4 px-6 text-center"
                        >
                          No Data Found
                        </td>
                      </tr>
                    ) : (
                      <>
                        {rows?.map((row, i) => (
                          <tr key={i} className="even:bg-lightSecondary/30">
                            {columns?.map((column) => (
                              <td key={column?.field} className="p-4 text-xs text-black">
                                {column?.render
                                  ? column?.render(row)
                                  : row[column?.field]}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </CardBody>

        {pagination ? (
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Button
              onClick={() => setPage(page - 1)}
              variant="outlined"
              size="sm"
              disabled={page === 1}
              className="text-black dark:text-white dark:bg-secondary bg-lightSecondary"
            >
              Previous
            </Button>
            <div className="md:flex items-center gap-2 hidden ">
              {Array.from({ length: totalPages }).map((_, index) => (
                <IconButton
                  key={index + 1}
                  variant="text"
                  size="sm"
                  onClick={() => setPage(index + 1)}
                  className={
                    index + 1 === page
                      ? "bg-blue-500 text-white"
                      : "bg-lightSecondary text-black dark:text-white dark:bg-secondary"
                  }
                >
                  {index + 1}
                </IconButton>
              ))}
            </div>
            <Button
              onClick={() => setPage(page + 1)}
              variant="outlined"
              size="sm"
              disabled={page === totalPages}
              className="text-black dark:text-white dark:bg-secondary bg-lightSecondary"
            >
              Next
            </Button>
          </CardFooter>
        ) : null}
      </Card>
    </>
  );
};

export default DataTable;
