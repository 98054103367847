import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  IconButton,
} from "@material-tailwind/react";
import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import React from "react";
import toast from "react-hot-toast";
import { fetcher } from "../../utils/authFetch";
import { useNavigate } from "react-router-dom";

const UpdatePlanModal = ({ open, handler }) => {
  const user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;
  const navigate = useNavigate();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (data) =>
      await fetcher.post("/api/payment/cancel-subscription", data),
    onSuccess: (res) => {
      toast.success(
        res?.data?.message || "Subscription cancelled successfully!"
      );
      navigate("/pricing");
    },
    onError: (err) => {
      toast.error(err?.response?.data.message || "Something went wrong!");
    },
  });

  const handleCancel = async () => {
    await mutateAsync({
      subscriptionId: user?.fields?.Subscription_id,
      email: user?.fields?.Email,
    });
    handler();
  };

  return (
    <Dialog
      open={open}
      handler={handler}
      className="bg-primary max-h-[90vh] overflow-auto"
      size="sm"
    >
      <DialogHeader className="text-white text-center justify-between">
        <h2>Update Subscription</h2>
        <IconButton
          color="white"
          variant="text"
          onClick={handler}
          className="rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </DialogHeader>
      <DialogBody className="p-6">
        <p className="text-gray-200 text-lg">
          Are you sure you want to update your current subscription?
          <br />
          If you update your subscription, your current plan will be{" "}
          <span className="text-red-500 font-bold">cancelled.</span>
        </p>
        <div className="flex justify-end gap-4 mt-12">
          <Button
            className="bg-[#3f51b5] tracking-wider px-12"
            size="lg"
            onClick={handler}
          >
            No
          </Button>
          <Button
            className="bg-red-500 tracking-wider px-12"
            size="lg"
            onClick={handleCancel}
          >
            {isPending ? "Processing..." : "Cancel & Update"}
          </Button>
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default UpdatePlanModal;
