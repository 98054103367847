import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";
import { currentTime, userTimeZone } from "../utils/timeZone";

export const useAllHandicapData = ({ date, skip, finished }) => {
  return useQuery({
    queryKey: ["handicap", date],
    queryFn: async () => {
      const response = await fetcher.get("/api/handicap", {
        params: {
          date,
          finished: finished ? finished : undefined,
          time: currentTime,
          timeZone: userTimeZone,
        },
      });
      return response.data;
    },
    enabled: !skip,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
};


// version 2
// export const useHandicap = ({date, filter, finishedFilterDate}) => {
//   return useQuery({
//     queryKey: ["handicap", date, filter, finishedFilterDate],
//     queryFn: async () => {
//       const response = await fetcher.get("/api/handicap/v2", {
//         params: {
//           date: date || new Date().toISOString(),
//           zone: userTimeZone,
//           filter: filter || "all",
//           finishedFilterDate
//         },
//       });
//       return response.data;
//     },

//     refetchOnMount: false,
//     refetchOnWindowFocus: false,
//     keepPreviousData: true,
//     cacheTime: 1000 * 60 * 5, // 5 minutes
//   });

// };


export const useHandicap = ({ date, filter, finishedFilterDate }, shouldFetchData) => {
  return useQuery({
    queryKey: ["handicap", date, filter, finishedFilterDate],
    queryFn: async () => {
      const response = await fetcher.get("/api/handicap/v2", {
        params: {
          date: date || new Date().toISOString(),
          zone: userTimeZone,
          filter: filter || "all",
          finishedFilterDate,
        },
      });
      return response.data;
    },
    enabled: shouldFetchData, // Only enable this query if shouldFetchData is true
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
};

