import React, { useEffect } from "react";
import AuthContainer from "../components/Auth/AuthContainer";
import LoginForm from "../components/Auth/LoginForm";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;
  const token = Cookies.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (user && token) {
      navigate("/user-guide");
    }
  }, []);

  return (
    <AuthContainer>
      <LoginForm />
    </AuthContainer>
  );
};

export default Login;
