import { useState } from 'react';

const CreateCategory = ({
  categoriesLoading,
  allCategories,
  setCategory,
  category,
  emptyFieldError,
  existingData,
}) => {
  const [previousCategory, setPreviousCategory] = useState(category);

  // category selection
  const handleSelectCategory = (selectedCategory) => {
    setCategory(selectedCategory);
    setPreviousCategory(null);
  };

  return (
    <>
      {categoriesLoading ? (
        <p className="text-gray-500 text-center w-full">Loading...</p>
      ) : (
        <div className="w-full flex flex-col justify-start items-start gap-2">
          <label htmlFor="category" className="font-medium">
            Category
          </label>

          {/* existing categories */}
          <div className="flex flex-wrap justify-start items-center gap-3">
            {allCategories?.map((singleCategory, idx) => (
              <button
                key={idx}
                className={` font-medium capitalize px-1 py-0.5 rounded ${
                  category?.includes(singleCategory) ||
                  previousCategory === singleCategory
                    ? 'text-white bg-blue-500'
                    : 'text-blue-500'
                }`}
                onClick={() => handleSelectCategory(singleCategory)}
              >
                {singleCategory}
              </button>
            ))}
          </div>

          {allCategories?.data?.categories.length >= 1 && (
            <p className="w-full bg-gray-200 my-2 text-gray-800 font-medium text-center">
              or,
            </p>
          )}

          <input
            type="text"
            onChange={(e) => setCategory(e.target.value)}
            name="category"
            id="category"
            placeholder="Enter new category"
            className="focus:outline-none px-3 py-1 border border-gray-300 focus:border-gray-500 rounded-md w-full"
          />
          {/* empty field caution */}
          {emptyFieldError && !category && (
            <p className="text-red-600 bg-[#ff868636] font-semibold px-2 py-0.5 rounded text-sm">
              Please enter category
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default CreateCategory;
