import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";

const token = Cookies.get("kodeend_token");

export const useChatbot = ({ page = 1, limit = 10 }) => {
  return useQuery({
    queryKey: ["chatbot", page, limit],
    queryFn: async () => {
      const response = await axios.get(
        "https://api.kodeend.com/api/v1/chatbot",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page,
            limit,
            domain: "betterreturn.net",
          },
        }
      );
      return response.data;
    },
    enabled: !!token,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useSingleChatbot = (id) => {
  return useQuery({
    queryKey: ["chatbot", id],
    queryFn: async () => {
      const response = await axios.get(
        `https://api.kodeend.com/api/v1/chatbot/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            domain: "betterreturn.net",
          },
        }
      );
      return response.data;
    },
    enabled: !!id,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

// https://api.kodeend.com/api/v1/chatbot?page=1&limit=10&domain=betterreturn.net

// https://api.kodeend.com/api/v1/chatbot/66600d0cfdc1414e61fc49c3    // single chatbot
